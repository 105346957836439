import React, { useEffect, useState } from 'react'
import {Button, Col, Layout, Modal, Radio, Row, Select, Space} from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import axios from 'axios'
import {format, parseISO} from 'date-fns'
import { useRequestContext } from '../../Contexts/RequestContext'
import AppLayout from '../Layouts'
import BitTable from '../BitTable'
import PageTitle from '../PageTitle'
import SearchFilter, { FilterByStatus, PageSizeSelect, Search } from '../SearchFilter'
import { FETCH_BUY_REQUEST } from '../../Contexts/type'

const {REACT_APP_BASE_URL} = process.env

function Admins() {

   const {state, dispatch } =  useRequestContext()
   const [accounts, setAccounts] = useState([])


//   Filter States
   const [pageSize, setPageSize] = useState(10)
   const [statusFilter]= useState('Both')
   const [user, setUser] = useState(undefined)
   const [role, setRole] = useState('admin')
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [isRoleModalVisible, setIsRoleModalVisible] = useState(false)

   async function getBuyRequest() {
    const {Content} = Layout
    const url = REACT_APP_BASE_URL + 'admins/admins'
   try {
    const token = window.localStorage.getItem('s_token')
    if(!token || typeof token === 'undefined' || token === null) return

    const response = await axios.get(url,{
    headers: {
        'Authorization': `Bearer ${token}`
    }
    })
  console.log(response.data)
    if(response.status === 200 && response.data && Array.isArray(response.data.users)) {
        const users = response.data.users.map(u => ({
            ...u,
            name: fullname(u)
        }))
        setAccounts(users)
    }

   } catch (error) {

   
   } 
}

useEffect(() => {
    getBuyRequest()
}, [])


/*  ------------
----------------PAGE FILTERS ----------
--------------------------------------- */

// Handle Page SizeChange
function handleChange(value) {
    console.log(`selected ${value}`);
    setPageSize(+value)
  }


//   Handle Status Filter
const statusOptions = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'Gold',
        value: 'Gold'
    },
    {
        label: 'Silver',
        value: 'Silver'
    }
] 




// Name Search
function onSearch(value) {
    console.log(value);
}

const btnStyle = {
    border:'none',
    paddingRight:'.5rem', 
    paddingLeft:'.5rem', 
    backgroundColor: '#F5F5F5', 
    display: 'flex', 
    alignItems: 'center'
}   
const columns = [
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    },     
    {
        dataIndex: 'role',
        title: 'Role',
        key: 'role',
        render: (text, record) => (
          <Space size="middle">
             <div>
                {/* {JSON.stringify(text)} */}
                {JSON.parse(JSON.stringify(record.roles[1]))}
            </div>
            
          </Space>
        ),
    },
    {
        key: 'phone',
        dataIndex: 'phone',
        title: 'Phone Number'
    },
    {
        key: 'email',
        dataIndex: 'email',
        title: 'Email'
    },
   
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
             <Button  onClick={() => handleRoleModal(record)} style={btnStyle} shape="round"  size={13}>
             Change Role
             </Button>
             <Button onClick={() => handleModalVisible(record)} style={btnStyle} shape="round"  size={13}>
             Deactivate
             </Button>
            
          </Space>
        ),
      },
    
]


function fullname(user) {
    return user && user.firstName && user.lastName  && user.firstName + ' ' + user.lastName
  }
  

//  Delete Modal
const handleModalVisible = (user) => {
    console.log(user)
    setUser(user)
    setIsModalVisible(true)
}
const handleOk = () => {
    console.log(user)
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
function renderDeleteModal() {
    // rate.title 
    return (
        <Modal title={'Deactivate ' + fullname(user) } visible={isModalVisible} onCancel={handleCancel} footer={null} width={400}>
        <p>What happens when you delete admin member?.</p>
        <p>The userwill no longer be able to sign in to his or her account.</p>
        <div style={{ width: '100%', display:'flex', justifyContent:'space-around' }} >
            <Button onClick={handleCancel} style={{ borderRadius: '5px' }} block type='default'>No</Button>
              <div style={{width: '20px'}} />
            <Button onClick={handleOk} style={{ borderRadius: '5px' }} block type='primary'>Yes</Button>
        </div>
      </Modal>
    )
}


// Update Modal
const handleRoleModal = (user) => {
    console.log(user)
    setUser(user)
    setIsRoleModalVisible(true)
}

const handleRoleCancel = () => {
    setIsRoleModalVisible(false);
  };
const onRoleChange = e => {
    console.log('radio checked', e.target.value);
    setRole(e.target.value)
    setIsModalVisible(false);
  };

function renderUpdateModal() {
    // rate.title 
    return (
        <Modal title={'Change ' + fullname(user) + '\'s role' } visible={isRoleModalVisible} onCancel={handleRoleCancel} footer={null} width={400}>
        <p>{'Choose what role ' + fullname(user) + ' will have below:'}</p>
        <Radio.Group onChange={onRoleChange} value={role} style={{marginBottom: '1.3rem'}}>
        <Space direction="vertical">
          <Radio value='admin'>Admin</Radio>
          <Radio value='moderator'>Moderator</Radio>
          <Radio value='editor'>Editor</Radio>
        </Space>
      </Radio.Group>
        <div style={{ width: '100%', display:'flex', justifyContent:'space-around' }} >
            <Button onClick={handleRoleCancel} style={{ borderRadius: '5px' }} block type='default'>No</Button>
              <div style={{width: '20px'}} />
            <Button onClick={onRoleChange} style={{ borderRadius: '5px' }} block type='primary'>Yes</Button>
        </div>
      </Modal>
    )
}

    return (
       
                <>
                <PageTitle title='Admins' />
                {renderDeleteModal()}
                {renderUpdateModal()}
                {/* Page Content */}
                <div className='bit-my bit-col' style={{flexDirection: 'column'}}>
                 
                <Row >
                    <Col style={{width: '100%'}}>
                        <BitTable 
                          dataSource={accounts} 
                          columns={columns} 
                          pagination={{ 
                              total: accounts.length,
                              // showSizeChanger: true,
                              // showQuickJumper: true,
                              showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
                              defaultPageSize: pageSize,
                              pageSize,
                              defaultCurrent: 1
                            }} 
                            />
                    </Col>
                </Row>
              </div>
              </>
          
    )
}

export default Admins

import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import forwardArrow from '../icons/forwardArrow.svg';
import edit from '../icons/edit.svg';
import del from '../icons/delete.svg'
import good from '../icons/good.svg';
import down from '../icons/down.svg'
import User from './User'



class Table extends Component {
    state = {
        rate: '',
        wallet: '',
        rateID: '',
        walletID: '',
        userU: '',
        showU: false,
        add: false,
        con: true,
        token: this.props.token,
        dosID: '',
        userT: '',
        userW: '',
        userId: '',
        layId: '',
        subC1: '',
        subC2: '',
    }
    subC1 = (e) => {
        this.setState({ subC1: e.target.value })

    }
    subC2 = (e) => {
        this.setState({ subC2: e.target.value })

    }


    subAdd = () => {

        let id = this.state.layId
        let data = {
            maturityDate: this.state.subC1,
            payback: this.state.subC2
        }
        fetch(`https://sanu-backend-api-99u5c.ondigitalocean.app/v1/admins/vending/${id}/approve_layaway`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.state.token
            },
            body: JSON.stringify(data)
        })
            .then(function (res) {
                return res.json()
            })
            .then((data) => {
                console.log('layyy', data)

            })

        this.setState({ add: false })

    }


    add = () => {
        this.setState({ add: !this.state.add })

    }
    handleChange = id => e => {
        this.setState({ rate: e.target.value, rateID: id })
        console.log(this.state)

    }

    handleChange1 = id => e => {
        this.setState({ wallet: e.target.value, walletID: id })
        console.log(this.state)

    }

    handleT = id => e => {
        this.setState({ dosID: id })
        console.log(this.state)

    }

    handleUID = id => e => {

        this.setState({ userId: id })
        console.log(this.state.userId)

        let userID = id
        console.log('userid', userID)
        fetch(`https://sanu-backend-api-99u5c.ondigitalocean.app/v1/admins/users/${userID}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.state.token

            },
        })
            .then(function (res) {
                console.log('in', res)
                return res.json()
            })
            .then((data) => {
                console.log('in', data)

                this.setState({ userD: data.user })
            })

        fetch(`https://sanu-backend-api-99u5c.ondigitalocean.app/v1/admins/user_wallets?userId=${userID}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.state.token

            },
        })
            .then(function (res) {
                console.log('in', res)
                return res.json()
            })
            .then((data) => {
                console.log('in', data)

                this.setState({ userW: data.userWallets })
            })

        fetch(`https://sanu-backend-api-99u5c.ondigitalocean.app/v1/admins/user_wallets/transactions?userId=${userID}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.state.token

            },
        })
            .then(function (res) {
                console.log('in', res)
                return res.json()
            })
            .then((data) => {
                console.log('in', data)

                this.setState({ userT: data })
            })

    }

    handleU = () => {

        this.setState({ showU: true })
        this.setState({ con: false })

    }

    layIdH = id => e => {
        this.setState({ layId: id })
        console.log(this.state)

    }


    handleSub = (e) => {
        e.preventDefault()
        let id = this.state.rateID
        let id1 = this.state.walletID
        let id3 = this.state.dosID

        let data = {}
        if (this.state.rate.length >= 1) {
            data = {
                value: this.state.rate,
            }
        } else {
            data = {
                title: this.state.wallet,
            }
        }
        console.log('rate or wallet', id3, data, this.props.token)

        // reciving this in console {email: "jjj", password: "j"}

        if (this.state.rate.length >= 1) {
            fetch(`https://sanu-backend-api-99u5c.ondigitalocean.app/v1/admins/rates/${id}`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: JSON.stringify(data)
            })
                .then(function (res) {
                    return res.json()
                })
                .then((data) => {
                    if (data.value) {
                        console.log('good', data)

                    } else {
                        console.log('bad', data)
                        this.setState({ msg: data.message })

                    }
                })
            console.log('rate', id, data, this.props.token)

        } else if (this.state.wallet.length >= 1) {
            console.log('wallet', id, data, this.props.token)
            fetch(`https://sanu-backend-api-99u5c.ondigitalocean.app/v1/admins/wallets/${id1}`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.token
                },
                body: JSON.stringify(data)
            })
                .then(function (res) {
                    return res.json()
                })
                .then((data) => {
                    console.log(data)

                })
        } else {
            console.log('dos', id3, this.state.token)
            fetch(`https://sanu-backend-api-99u5c.ondigitalocean.app/v1/admins/transfers/${id3}/verify`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.token
                },
            })
                .then(function (res) {
                    return res.json()
                })
                .then((data) => {
                    console.log(data)


                })
        }
    }



    render() {
        let b = this.props.body
        let active = this.props.active
        let de = this.props.de
        return (




            <div>

                {this.state.showU ? <User dataD={this.state.userD} dataW={this.state.userW} dataT={this.state.userT} /> : ''}



                {this.state.con ? <div>
                    <div style={{
                        marginTop: '5%', background: '#FFFFFF',
                        boxShadow: ' 0px 0px 2px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        width: '96%',

                    }} className='desktop'>
                        <div style={{
                            height: '13vh',
                        }}>
                            {this.props.tab}
                        </div>

                        <div style={{
                            background: '#F4F4F4',
                        }}>

                            {this.props.header.map((i) => {
                                return (
                                    <div style={{
                                        marginTop: '-15px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '95%',
                                        margin: '0 2%',
                                    }}>

                                        {i.sn ? <p className='tabP'>{i.sn}</p> : ''}

                                        {i.name ? <p className='tabP'>{i.name}</p> : ''}
                                        {i.value ? <p className='tabP'>{i.value}</p> : ''}
                                        {i.carat ? <p className='tabP'>{i.carat}</p> : ''}
                                        {i.weight ? <p className='tabP'>{i.weight}</p> : ''}
                                        {i.layaway ? <p className='tabP'>{i.layaway}</p> : ''}
                                        {i.matDate ? <p className='tabP'>{i.matDate}</p> : ''}


                                        {i.type  ? <p className='tabP'>{i.type}</p> : ''}
                                        {i.location ? <p className='tabP'>{i.location}</p> : ''}


                                        {i.phone ? <p className='tabP'>{i.phone}</p> : ''}
                                        {i.email ? <p className='tabP'>{i.email}</p> : ''}



                                        {i.flag ? <p className='tabP'>{i.flag}</p> : ''}

                                        {i.rate ? <p className='tabP'>{i.rate}</p> : ''}
                                        {i.currency ? <p className='tabP'>{i.currency}</p> : ''}
                                        {i.wallet ? <p className='tabP'>{i.wallet}</p> : ''}

                                        {i.title ? <p className='tabP'>{i.title}</p> : ''}
                                        {i.currencyCode ? <p className='tabP'>{i.currencyCode}</p> : ''}
                                        {i.countryCode ? <p className='tabP'>{i.countryCode}</p> : ''}


                                        {i.gold ? <p className='tabP'>{i.gold}</p> : ''}

                                        {i.buy ? <p className='tabP'>{i.buy}</p> : ''}

                                        {i.sell ? <p className='tabP'>{i.sell}</p> : ''}
                                        {i.percentage ? <p className='tabP'>{i.percentage}</p> : ''}

                                        {i.transactionType ? <p className='tabP'>{i.transactionType}</p> : ''}
                                        {i.entryType ? <p className='tabP'>{i.entryType}</p> : ''}
                                        {i.amount ? <p className='tabP'>{i.amount}</p> : ''}
                                        {i.createdAt ? <p className='tabP'>{i.createdAt}</p> : ''}

                                        {i.description ? <p className='tabP'>{i.description}</p> : ''}

                                        {i.status ? <p className='tabP'>{i.status}</p> : ''}
                                        {i.action ? <p className='tabP'>{i.action}</p> : ''}




                                    </div>
                                )
                            })
                            }
                        </div>
                        <div>
                            {b && b.length > 1 ? b.map((i, index) => {
                                return (
                                    <div>
                                        <div style={{
                                            marginTop: '-15px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'column',
                                            width: '93%',
                                            margin: '0 2%',
                                        }}>
                                            {/* {i.sn ? <p className='tabPB'>{i.sn}</p> : ''}
    
                                        {i.name ? <p className='tabPB'>{i.name}</p> : ''}
                                        {i.role ? <p className='tabPB'>{i.role}</p> : ''}
    
                                        {i.rate ? <p className='tabPB'>{i.rate}</p> : ''}
    
                                        {i.phone ? <p className='tabPB'>{i.phone}</p> : ''}
                                        {i.email ? <p className='tabPB'>{i.email}</p> : ''}
                                        {i.dateJ ? <p className='tabPB'>{i.dateJ}</p> : ''}
    
    
                                        {i.type ? <p className='tabPB'>{i.type}</p> : ''}
                                        {i.date ? <p className='tabPB'>{i.date}</p> : ''}
                                        {i.time ? <p className='tabPB'>{i.time}</p> : ''}
                                        {i.location ? <p className='tabPB'>{i.location}</p> : ''}
                                        {i.status ? <p className='tabPB'>{i.status}</p> : ''}
                                        {i.wallet ? <p className='tabPB'>{i.wallet}</p> : ''}
                                        {i.currency ? <p className='tabPB'>{i.currency}</p> : ''}
    
                                        {i.coin ? <p className='tabPB'>{i.coin}</p> : ''}
    
                                        {i.accName ? <p className='tabPB'>{i.accName}</p> : ''}
                                        {i.accNo ? <p className='tabPB'>{i.accNo}</p> : ''}
                                        {i.bank ? <p className='tabPB'>{i.bank}</p> : ''}
                                        {i.transactionCode ? <p className='tabPB'>{i.transactionCode}</p> : ''}
                                        {i.dateApp ? <p className='tabPB'>{i.dateApp}</p> : ''}
                                        {i.amountPaid ? <p className='tabPB'>{i.amountPaid}</p> : ''}
                                        {i.matDate ? <p className='tabPB'>{i.matDate}</p> : ''}
                                        {i.weight ? <p className='tabPB'>{i.weight}</p> : ''}
                                        {i.carat ? <p className='tabPB'>{i.carat}</p> : ''}
                                        {i.layaway ? <p className='tabPB'>{i.layaway}</p> : ''}
    
                                        {i.action ? <p className='tabPB'>{i.action}</p> : ''}
    
                                        {i.arrow ? <p className='tabPB'>{i.arrow}</p> : ''} */}
                                            <form onSubmit={this.handleSub} style={{ display: 'flex', width: '100%' }} >
                                                {i.sanuId ? <p className='tabPB'>{i.sanuId}</p> : ''}



                                                {i.type && this.props.type === true ? <p className='tabPB'>{i.type}</p> : ''}
                                                {i.delivery ? <p className='tabPB'>{i.delivery.type}</p> : ''}

                                                {i.delivery ? <p className='tabPB'>{i.delivery.address}</p> : ''}


                                                {i.firstName ? <p className='tabPB'>{i.firstName + ' '}{i.lastName}</p> : ''}
                                                {i.userId && i.userId.firstName ? <p className='tabPB' style={{ width: '25%' }}>{i.userId.firstName + ' '}{i.userId.lastName}</p> : ''}
                                                {!i.value && this.props.value ? <p className='tabPB'>{i.value === 0 ? '0' : i.value}</p> : ''}
                                                {i.carat ? <p className='tabPB'>{i.carat}</p> : ''}
                                                {i.weight ? <p className='tabPB'>{i.weight}</p> : ''}
                                                {/* {i.layaway ? <p className='tabPB'>{i.layaway}</p> : ''} */}


                                                {i.layaway !== undefined ? <p className='tabPB'>{`Return ${i.layaway.payback}%`}</p> : ''}
                                                {i.layaway !== undefined ? <p className='tabPB' style={{ whiteSpace: 'nowrap' }}>{i.layaway.maturityDate ? i.layaway.maturityDate.substr(0, 10) + '' : 'no mature date'}</p> : ''}




                                                {i.phone ? <p className='tabPB'>{i.phone}</p> : ''}
                                                {i.email ? <p className='tabPB'>{i.email.substr(0, 10) + '...'}</p> : ''}



                                                {i.flag && true === this.props.flag ? <div className='tabPB'><img src={i.flag} style={{
                                                    width: '45%', height: '55%',
                                                    margin: ' 12% 0'
                                                }} /> </div> : ''}

                                                {i.rate >= 0 ? <p className='tabPB'>1{i.currencies[0].code }= <input style={{ width: '35%', border: 'none' }} onChange={this.handleChange(i.rateId)} placeholder={i.rate} /></p> : ''}
                                                {i.currencies ? <p className='tabPB'>{i.currencies[0].code}</p> : ''}
                                                {i.currencies ? <p className='tabPB'>{i.currencies[0].name}</p> : ''}



                                                {i.title ? <input style={{ width: '35%', border: 'none' }} onChange={this.handleChange1(i._id)} placeholder={i.title} /> : ''}
                                                {i.currencyCode ? <p className='tabPB'>{i.currencyCode}</p> : ''}
                                                {i.countryCode ? <p className='tabPB'>{i.countryCode}</p> : ''}
                                                {i.displayName ? <p className='tabPB'>{i.displayName}</p> : ''}

                                                {i.rates ? <p className='tabPB'><input style={{ width: '35%', border: 'none', marginLeft:'10%' }} onChange={this.handleChange()} placeholder={i.rates[0].buying} /></p> : '' }

                                                {i.rates ? <p className='tabPB'><input style={{ width: '35%', border: 'none', marginLeft:'10%' }} onChange={this.handleChange()} placeholder={i.rates[0].buying} /></p> : '' }
                                                {i.percent ? <p className='tabPB'>SANUAUPRICE +{i.percent}%</p> : ''}
                                                {i.transactionType ? <p className='tabPB'>{i.transactionType}</p> : ''}
                                                {i.entryType ? <p className='tabPB'>{i.entryType}</p> : ''}
                                                {i.accountName ? <p className='tabPB'>{i.accountName}</p> : ''}
                                                {i.bank ? <p className='tabPB'>{i.bank}</p> : ''}

                                                {i.amount ? <p className='tabPB'>{i.amount}</p> : this.props.amount}
                                                {i.createdAt ? <p className='tabPB'>{i.createdAt.substr(0, 10) + ''}</p> : ''}


                                                {i.description ? <p className='tabPB'>{i.description}</p> : ''}

                                                {i.verified && this.props.verified ? <button onClick={this.handleU} className='tabPB' style={{ color: 'red', }}>true</button> : i.verified === undefined ? '' : <button className='tabPB' style={{ color: 'red', }}>{'false'}</button>}

                                                {i.status ? <p className='tabPB'>{i.status}</p> : ''}

                                                {/* {i.active === this.props.active ? <button onMouseEnter={this.handleUID(i._id)} onClick={this.handleU} className='tabPB' style={{ cursor: 'pointer', color: 'green' }}>Active</button> : this.props.de == '' || this.props.de == false ? '' : <button className='tabPB' style={{ cursor: 'pointer', color: 'red' }}>{de}</button>} */}


                                                {this.props.action === true ? <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: ' 13%'
                                                }} className='tabPB'><button type='submit' data-remove={index}><img style={{ cursor: 'pointer' }} src={edit} /></button><img src={del} style={{ marginLeft: '5%' }} /></div> : ''}

                                                {this.props.action1 === true ? <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: ' 13%'
                                                }} className='tabPB'><button type='submit' onMouseEnter={this.handleT(i._id)} data-remove={index}><img src={good} /></button></div> : ''}

                                                {this.props.arrow === true ? <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: ' 13%'
                                                }} className='tabPB'><button type='submit' onClick={this.add} ><img style={{ cursor: 'pointer' }} src={down} /></button></div> : ''}

                                            </form>
                                            <br></br>

                                            {this.state.add ?
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '-33px 0 7px'

                                                }}>
                                                    {i.imageUri ? <img style={{
                                                        width: ' 3%',
                                                        height: '45%',
                                                        marginTop: '1.5%',
                                                    }} src={i.imageUri} className='tabPB' /> : ''}

                                                    <div className='tabPB' style={{ width: '30%' }}>
                                                        <p>maturityDate</p>
                                                        <input onChange={this.subC1} style={{ width: '55%', }} placeholder='2020-10-24' />
                                                    </div>
                                                    <div className='tabPB' style={{ width: '30%' }}>
                                                        <p>payback</p>
                                                        <input onChange={this.subC2} style={{ width: '55%', }} placeholder='amount' />
                                                    </div>

                                                    {this.props.action2 === true ? <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: ' 13%',
                                                        marginTop: '20px'
                                                    }} className='tabPB' ><button type='submit' onClick={this.subAdd} onMouseEnter={this.layIdH(i._id)} data-remove={index}><img src={good} /></button></div> : ''}

                                                </div> : ''}

                                        </div>
                                        <Divider />
                                    </div>


                                )
                            }) : 

                            <div className='noData'>
                                no data
                            </div>
                            }
                        </div>
                        <div style={{
                            padding: '3%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>      <div style={{
                            display: 'flex',
                            width: '28%',

                        }}>
                                <p>Viewing {b ? '1' : '0'} -  {b ? b.length : '0'} of {b ? b.length : '0'}</p>
                                <div className='arrow'>
                                    <img src={forwardArrow} />
                                </div>
                                <div className='arrow' style={{
                                    paddingTop: '4%', height: '47%'
                                }}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 -6.03337e-07L14 7L7 14L7 -6.03337e-07Z" fill="black" />
                                    </svg>

                                </div>

                            </div>
                        </div>



                    </div >




                    <div className='mobile'>
                        {b && b.length > 1 ? b.map((i) => {
                            return (
                                <div style={{
                                    display: 'flex', background: '#FFFFFF',
                                    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '4px',
                                    margin: '2% 0',
                                    width: '93%',
                                }}>
                                    <div style={{
                                        marginTop: '-15px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '90%',
                                        margin: '0 2%',
                                        flexDirection: 'column',
                                        background: '#F4F4F4',
                                        borderRadius: '4px',
                                    }}>
                                        {this.props.header[0].sn ? <p className='tabP'>{this.props.header[0].sn}</p> : ''}

                                        {this.props.header[0].name ? <p className='tabP'>{this.props.header[0].name}</p> : ''}


                                        {this.props.header[0].phone ? <p className='tabP'>{this.props.header[0].phone}</p> : ''}
                                        {this.props.header[0].email ? <p className='tabP'>{this.props.header[0].email}</p> : ''}





                                        {this.props.header[0].flag ? <p className='tabP'>{this.props.header[0].flag}</p> : ''}

                                        {this.props.header[0].rate ? <p className='tabP'>{this.props.header[0].rate}</p> : ''}
                                        {this.props.header[0].currency ? <p className='tabP'>{this.props.header[0].currency}</p> : ''}
                                        {this.props.header[0].wallet ? <p className='tabP'>{this.props.header[0].wallet}</p> : ''}


                                        {this.props.header[0].title ? <p className='tabP'>{this.props.header[0].title}</p> : ''}
                                        {this.props.header[0].currencyCode ? <p className='tabP'>{this.props.header[0].currencyCode}</p> : ''}

                                        {this.props.header[0].countryCode ? <p className='tabP'>{this.props.header[0].countryCode}</p> : ''}

                                        {this.props.header[0].transactionType ? <p className='tabP'>{this.props.header[0].transactionType}</p> : ''}
                                        {this.props.header[0].entryType ? <p className='tabP'>{this.props.header[0].entryType}</p> : ''}
                                        {this.props.header[0].createdAt ? <p className='tabP'>{this.props.header[0].createdAt}</p> : ''}

                                        {this.props.header[0].amount ? <p className='tabP'>{this.props.header[0].amount}</p> : ''}

                                        {this.props.header[0].description ? <p className='tabP'>{this.props.header[0].description}</p> : ''}

                                        {this.props.header[0].action ? <p className='tabP'>{this.props.header[0].action}</p> : ''}
                                        {this.props.header[0].status ? <p className='tabP'>{this.props.header[0].status}</p> : ''}


                                    </div>
                                    <div style={{
                                        marginTop: '-15px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '90%',
                                        margin: '0 2%',
                                        background: 'white',
                                        flexDirection: 'column',

                                    }}>

                                        {/* {i.sn ? <div><p className='tabPB'>{i.sn}</p>< Divider /></div> : ''}
    
    
                                    {i.name ? <div><p className='tabPB'>{i.name}</p>< Divider /></div> : ''}
                                    {i.role ? <div><p className='tabPB'>{i.role}</p>< Divider /></div> : ''}
    
                                    {i.rate ? <div><p className='tabPB'>{i.rate}</p>< Divider /></div> : ''}
    
                                    {i.phone ? <div><p className='tabPB'>{i.phone}</p>< Divider /></div> : ''}
                                    {i.email ? <div><p className='tabPB'>{i.email}</p>< Divider /></div> : ''}
                                    {i.dateJ ? <div><p className='tabPB'>{i.dateJ}</p>< Divider /></div> : ''}
    
    
    
                                    {i.date ? <div><p className='tabPB'>{i.date}</p>< Divider /></div> : ''}
    
                                    {i.type ? <div><p className='tabPB'>{i.type}</p> < Divider /></div> : ''}
    
                                    {i.time ? <div><p className='tabPB'>{i.type}</p>< Divider /></div> : ''}
    
    
                                    {i.location ? <div><p className='tabPB'>{i.location}</p>< Divider /></div> : ''}
    
                                    {i.status ? <div><p className='tabPB'>{i.status}</p> < Divider /></div> : ''}
    
                                    {i.wallet ? <div><p className='tabPB'>{i.wallet}</p>< Divider /></div> : ''}
    
                                    {i.currency ? <div><p className='tabPB'>{i.currency}</p> < Divider /></div> : ''}
    
                                    {i.coin ? <div><p className='tabPB'>{i.coin}</p>< Divider /></div> : ''}
    
    
                                    {i.accName ? <div><p className='tabPB'>{i.accName}</p>< Divider /></div> : ''}
    
                                    {i.accNo ? <div><p className='tabPB'>{i.accNo}</p>< Divider /></div> : ''}
    
                                    {i.bank ? <div><p className='tabPB'>{i.bank}</p> < Divider /></div> : ''}
    
                                    {i.transactionCode ? <div><p className='tabPB'>{i.transactionCode}</p>< Divider /></div> : ''}
    
    
                                    {i.dateApp ? <div><p className='tabPB'>{i.dateApp}</p>< Divider /></div> : ''}
    
                                    {i.amountPaid ? <div><p className='tabPB'>{i.amountPaid}</p>< Divider /></div> : ''}
    
                                    {i.matDate ? <div><p className='tabPB'>{i.matDate}</p>< Divider /></div> : ''}
    
                                    {i.weight ? <div><p className='tabPB'>{i.weight}</p>< Divider /></div> : ''}
    
                                    {i.carat ? <div><p className='tabPB'>{i.carat}</p>< Divider /></div> : ''}
    
                                    {i.layaway ? <div><p className='tabPB'>{i.layaway}</p>< Divider /></div> : ''}
    
    
                                    {i.action ? <div><p className='tabPB'>{i.action}</p>< Divider /></div> : ''}
    
                                    {i.arrow ? <div><p className='tabPB'>{i.arrow}</p>< Divider /></div> : ''} */}


                                        {i.sanuId ? <div><p className='tabPB'>{i.sanuId}</p>< Divider /></div> : ''}

                                        {i.firstName ? <div><p className='tabPB'>{i.firstName + ' '}{i.lastName}</p>< Divider /></div> : ''}
                                        {i.phone ? <div><p className='tabPB'>{i.phone}</p>< Divider /></div> : ''}
                                        {i.email ? <div><p className='tabPB'>{i.email}</p> < Divider /></div> : ''}



                                        {i.flag && true === this.props.flag ? <div> <div className='tabPB'><img src={i.flag} style={{
                                            width: '100%',
                                            margin: '35% 0%'
                                        }} /></div>< Divider /></div> : ''}

                                        {i.rate >= 0 ? <div><p className='tabPB'>{i.currencies[0].code}={i.rate}</p>< Divider /></div> : ''}
                                        {i.currencies ? <div><p className='tabPB'>{i.currencies[0].code}</p>< Divider /></div> : ''}
                                        {i.currencies ? <div><p className='tabPB'>{i.currencies[0].name}</p> < Divider /></div> : ''}

                                        {i.title ? <div><p className='tabPB'>{i.title}</p>< Divider /></div> : ''}
                                        {i.currencyCode ? <div><p className='tabPB'>{i.currencyCode}</p>< Divider /></div> : ''}
                                        {i.countryCode ? <div><p className='tabPB'>{i.countryCode}</p> < Divider /></div> : ''}


                                        {i.transactionType ? <div><p className='tabPB'>{i.transactionType}</p>< Divider /></div> : ''}
                                        {i.entryType ? <div><p className='tabPB'>{i.entryType}</p>< Divider /></div> : ''}
                                        {i.createdAt ? <div><p className='tabPB'>{i.createdAt.substr(0, 10) + ''}</p>< Divider /></div> : ''}

                                        {i.amount ? <div><p className='tabPB'>{i.amount}</p> < Divider /></div> : this.props.amount}

                                        {i.description ? <div><p className='tabPB'>{i.description}</p>< Divider /></div> : ''}
                                        {this.props.action === true ? <div><div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: ' 13%'
                                        }} className='tabPB'><img src={edit} /><img src={del} style={{ marginLeft: '5%' }} /></div>< Divider /></div> : ''}

                                        {i.active === { active } ? <div><button onClick={this.handleU} className='tabPB'>Active</button> < Divider /></div> : de}



                                    </div>
                                </div>


                            )
                        }) : ""
                        }
                    </div>
                </div> : ''}
            </div>
        );
    }
}




export default Table;

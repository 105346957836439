import React, { Component } from 'react';
import { Divider } from '@material-ui/core';
import user from '../icons/user.svg'
import UserCard from './UserCard'
import TableS from './TableS'
import Activities from './activities'
import ActivityCard from './ActivityCard';


class User extends Component {


    render() {
        console.log('from user', this.props.dataW, this.props.dataT)
        return (
            <div>
                <div className="content-titleSection">

                    <div className='subNavLink'>
                        <p style={{
                            fontWeight:400,
                            fontSize: '16px',
                            lineHeight: '19px',
                            /* identical to box height */


                            color: ' #800020'
                        }}>Users</p>
                        <p style={{
                            color: ' #686B6F', fontWeight:500,
                            fontSize: '20px',
                            lineHeight: '19px',
                            marginTop: '8%',

                        }}>&gt;</p>
                        <p style={{
                            color: ' #828282',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '19px',
                        }}>{this.props.dataD ? this.props.dataD.firstName + ' ' + this.props.dataD.lastName : ''}</p>
                    </div>

                </div>
                <div className="content-box" style={{ width: '93%' }}>
                    <div className='userDetailsP'>

                        <div>
                            <h3 className='userDetailsH'>Personal Info</h3>
                            <div style={{
                                width: '18rem',
                            }}>
                                <div>
                                    <div className='userDetailsT'>
                                        <p>Name:</p>
                                        <p>{ this.props.dataD ? this.props.dataD.firstName + ' ' + this.props.dataD.lastName : ''}</p>
                                    </div>
                                    <Divider />
                                </div>

                                <div>
                                    <div className='userDetailsT'>
                                        <p>Phone no:</p>
                                        <p>{this.props.dataD ? this.props.dataD.phone :''} </p>
                                    </div>
                                    <Divider />
                                </div>

                                <div>
                                    <div className='userDetailsT'>
                                        <p>Email address:</p>
                                        <p>{this.props.dataD ? this.props.dataD.email: ''}</p>
                                    </div>
                                    <Divider />
                                </div>



                            </div>
                        </div>
                        <div style={{
                            margin: ' 0 auto'
                        }}>
                            <img src={user} />
                        </div>
                        <div style={{
                            marginTop: '23%'
                        }}>
                            <button className='btn1'>Deactivate User</button>
                        </div>


                    </div>

                    <div className='userDetails'>

                        <div style={{ width: '100%' }}>
                            <h3 className='userDetailsH'>Wallet Balance</h3>

                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '100%',
                            }}>
                                {this.props.dataW ? this.props.dataW.map((i) =>
                                    <UserCard name={i.currencyCode} img={i.flag} amount={i.balance} />

                                ) : ''}


                            </div>


                        </div>



                    </div>



                    <div className='userDetails'>

                        <div style={{ width: '100%' }}>
                            <h3 className='userDetailsH'>Activites</h3>

                            <div>
                                {this.props.dataT.transactions ? this.props.dataT.transactions.length > 1 ? this.props.dataT.transactions.map((i) => 
                                    <Activities  date={i.createdAt} amount={i.balance} />

                                ) : <p>No Current Transactions</p> : <p>No Current Transactions</p> }
                            </div>


                        </div>




                    </div>

                </div>
            </div >
        );
    }
}


export default User;

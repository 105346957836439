import React, { Component, useState } from 'react';
import Sanu from '../icons/SanuL.svg'
// import Dashboard from './Dashboard'
import { useHistory } from 'react-router-dom'   

import '../App.css';

const { REACT_APP_BASE_URL } = process.env;
const Login = () => {


   

    const [ state, setState ] = useState({
        email: '',
        password: '',
        data: '',
        status: true,
        msg: '',
        username:''
    })

    const router = useHistory();

  
  const   handleEmailChange = (e) => {
        setState({...state, email: e.target.value })
        console.log(state)
    
      }
     const  handlePasswordChange = (e) => {
        setState({...state, password: e.target.value })
        console.log(state)
    
      }
  const handleSub = (e) => {
        e.preventDefault()
        let data = {
          email: state.email,
          password: state.password
        }
        console.log('in', data)
    
        // reciving this in console {email: "jjj", password: "j"}
    
        fetch(`${REACT_APP_BASE_URL}/auth/login`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
          .then(function (res) {
            return res.json()
          })
          .then((data) => {
           
             localStorage.setItem('s_token', data.token);
             localStorage.setItem('s_user_id', data.user._id);
             router.push('/dashboard')
           if (data.token){
            console.log('good')
    
            setState({ data: data.token, status:true, username: data.user.firstName})
           }else{
             console.log('bad', )
            setState({ msg: data.message, status:false})
    
           }
          }).catch((e)=> {

          })
      }
       



     

        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: ' 7%',
                    // height='550px',
                    // width: '750px'
                    

                }}>
                    <div className='loginDiv'>
                        <div><img src={Sanu} alt='' style={{
                            height: '70px',
                            width: '170px'
                        }}/></div>
                        <div className='mx-auto'>
                            <p style={{
                                marginTop:'50px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 500,
                                fontSize: '30px',
                                lineHeight: '36px',
                                color: '#021C1E',
                            }}>Welcome</p>
                        </div>

                        <form onSubmit={handleSub} className='w-100 px-5'>

                            <div >
                                <div style={{
                                    marginBottom: '8%',
                                }}>
                                    <p style={{
                                        fontSize: '13px',
                                        lineHeight: '16px',
                                        color: '#E64141',
                                    }}>{state.msg}</p>

                                    <p className='inputsL'>Email address</p>
                                    <input name='email' onChange={handleEmailChange} className='inputsW' style={{ width: '100%' }} placeholder='name@gmail.com'/>
                                </div>

                                <div style={{
                                    marginBottom: '13%',
                                }}>
                                    <p className='inputsL'>  Password</p>
                                    <input name='password' onChange={handlePasswordChange} type='password' className='inputsW' style={{ width: '100%' }} placeholder='password'/>
                                </div>

                                <p style={{
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    fontWeight: 500,
                                    /* identical to box height, or 129% */


                                    color: '#800020',
                                    cursor: "pointer"

                                }} > Forgot Password</p>





                                <div style={{
                                    marginBottom: '5%',
                                }}>
                                    <button type='submit' className='btn btn-login' style={{
                                        width: '100%', cursor: "pointer"
                                    }}>Login </button>
                                </div>
                            </div>

                        </form>


                    </div>

                </div>

            </div >
        );
    

}


export default Login;

import React, { useEffect, useState } from 'react'
import {Button, Col, Layout, Row, Space} from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import axios from 'axios'
import {format, parseISO} from 'date-fns'
import { useRequestContext } from '../Contexts/RequestContext'
import AppLayout from '../Components/Layouts'
import BitTable from '../Components/BitTable'
import PageTitle from '../Components/PageTitle'
import SearchFilter, { FilterByStatus, PageSizeSelect, Search, SelectDatePickers } from '../Components/SearchFilter'
import { FETCH_BUY_REQUEST } from '../Contexts/type'

const {REACT_APP_BASE_URL} = process.env


function Deposits() {
   const {Content} = Layout
   const {state, dispatch } =  useRequestContext()
   const [buyRequests, setBuyRequests] = useState([])
   const [tableData, setTableData] = useState([])
   const [pageTitle, setPageTitle] = useState('New Deposits')
   const [activeMenu, setActiveMenu] = useState('New')
   const [deposits, setDeposits] = useState([]);

//   Filter States
   const [pageSize, setPageSize] = useState(10)
   const [statusFilter]= useState('All')


   const fetchDeposits = async () => {
    try {
      const { data } = await axios.get('admins/transfers')
      console.log(data)
    //   setDeposits(data);
    } catch (error) {
        console.log(error)
    }
   }

    useEffect(()=> {
        fetchDeposits()
    },[])

   async function getBuyRequest() {

    if(state && Array.isArray(state.buyRequests) && state.buyRequests.length > 0) {
        const br = state.buyRequests.map(row => ({
            key: row._id.toString(),
            location: row.delivery && row.delivery.address ? row.delivery.address  : '',
            date: format(parseISO(row.createdAt), 'MM-dd-yyyy') ,
            time: format(parseISO(row.createdAt), 'HH:mm:ss aaaa'),
            ...row
        })
        );
         setBuyRequests(br)
         setTableData(br)
         return;
    }
    const url = REACT_APP_BASE_URL + 'admins/vending/buy_requests'
   try {
    const token = window.localStorage.getItem('s_token')
    if(!token || typeof token === 'undefined' || token === null) return

    const response = await axios.get(url,{
    headers: {
        'Authorization': `Bearer ${token}`
    }
    })

    if(response.status === 200 && response.data && Array.isArray(response.data.buyRequests)) {
        let br = response.data.buyRequests

        br = br.map(row => ({
            key: row._id.toString(),
            location: row.delivery && row.delivery.address ? row.delivery.address  : '',
            date: format(parseISO(row.createdAt), 'MM-dd-yyyy') ,
            time: format(parseISO(row.createdAt), 'HH:mm:ss'),
            ...row
        })
        );
        // dispatch
        dispatch({
            type: FETCH_BUY_REQUEST,
            buyRequests: response.data
        })

      
        setBuyRequests(br)
        setTableData(br)
    }

   } catch (error) {

    // alert an error message
     setBuyRequests([])
     setTableData([])
   } 
}

useEffect(() => {
    getBuyRequest()
}, [])


/*  ------------
----------------PAGE FILTERS ----------
--------------------------------------- */

// Handle Page SizeChange
function handleChange(value) {
    console.log(`selected ${value}`);
    setPageSize(+value)
  }


//   Handle Status Filter
const statusOptions = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'Pending',
        value: 'Pending'
    },
    {
        label: 'Paid',
        value: 'Paid'
    },
 
] 

function handleStatusChange(value) {
    if(value.toLowerCase() !== 'all'){
        const ds = buyRequests.filter((br) => typeof br.type === 'string' && br.type.toLowerCase() === value.toLowerCase() )
        setTableData(ds)
        return
    }
    setTableData(buyRequests)
      
}


// Name Search
function onSearch(value) {
    console.log(value);
}


// Date Filters
function onStartDate(date, dateString) {
    console.log(date, dateString)
}

function onEndDate(date, dateString) {
    console.log(date, dateString)
}

const btnStyle = {paddingRight:'.3rem', paddingLeft:'.3rem', backgroundColor: '#F5F5F5', display: 'flex', alignItems: 'center'} 
const columns = [
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    },
    {
        key: 'date',
        dataIndex: 'date',
        title: 'Date'
    },
    {
        key: 'time',
        dataIndex: 'time',
        title: 'Time'
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: 'Type'
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: 'Amount'
    },
    {
        key: 'location',
        dataIndex: 'location',
        title: 'Location'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
           <Button  style={btnStyle} shape="round" icon={<CheckOutlined  />} size={10}>
            Approve
           </Button>
           <Button  style={btnStyle} shape="round" icon={<CloseOutlined />} size={10}>
            Reject
           </Button>
          
        </Space>
      ),
    },
]


function renderSubMenu() {
    const subMenuStyle = {
        backgroundColor: 'white', 
        borderTopStyle: 'solid',
        borderTopColor: 'rgb(243 243 243)',
        borderTopWidth: '1px',
        padding: '1rem', 
        paddingLeft: '40px',
        paddingRight: '40px'
    }

    const btnStyle= {
        border:'none',
        boxShadow: 'none',
        color: '#94979B',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem'
    }

    const activeStyle = {
        backgroundColor: 'rgba(128, 0, 32, 0.05)',
        border: 'none',
        color: '#800020',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem'
    }

    function changeMenu(menu) {
        setActiveMenu(menu)
        if(menu === 'New'){
            setPageTitle(menu + ' Deposits')
        } else {
            setPageTitle(menu)
        }
    }
    
    return (
        <Row style={subMenuStyle}>
            <Col span={6}>
                <Space direction='horizontal'>
                <Button onClick={() => changeMenu('New')} style={activeMenu === 'New' ? activeStyle : btnStyle} shape="round" size='middle'>
                   New
                </Button>
                <Button onClick={() => changeMenu('All Deposits')} style={activeMenu === 'All Deposits' ? activeStyle : btnStyle}  shape="round" size='middle'>
                   All Deposits
                </Button>
                </Space>
            </Col>
        </Row>
    )
}

    return (
        <AppLayout SubMenu={renderSubMenu()}>
            <Content>
                {/* Page Header */}
                <PageTitle title={pageTitle} />

                {/* Page Content */}
                <div className='bit-my bit-col' style={{flexDirection: 'column'}}>
                    <SearchFilter 
                      pageSizeFilter={{
                        enabled: true,
                        render: <PageSizeSelect handleChange={handleChange} />
                      }} 
                      statusFilter={{
                          enabled: true,
                          render: <FilterByStatus defaultValue={statusFilter} options={statusOptions} handleChange={handleStatusChange} />
                      }}
                      
                      datePickerFilter={{
                          enabled: true,
                          render: <SelectDatePickers onStartDate={onStartDate} onEndDate={onEndDate} />
                      }}
                      searchFilter= {<Search  onSearch={onSearch} />}
                     
                    />
                <Row >
                    <Col style={{width: '100%'}}>
                        {
                            activeMenu === 'Fiat' 
                            ?
                            // Fiat
                            <BitTable 
                          dataSource={[]} 
                          columns={columns} 
                          pagination={{ 
                              total: tableData.length,
                              // showSizeChanger: true,
                              // showQuickJumper: true,
                              showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
                              defaultPageSize: pageSize,
                              pageSize,
                              defaultCurrent: 1
                            }} 
                            />

                            :
                            // All Deposits table
                            <BitTable 
                            dataSource={[]} 
                            columns={columns} 
                            pagination={{ 
                                total: tableData.length,
                                // showSizeChanger: true,
                                // showQuickJumper: true,
                                showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
                                defaultPageSize: pageSize,
                                pageSize,
                                defaultCurrent: 1
                              }} 
                              />
                        }
                        
                    </Col>
                </Row>
              </div>
            </Content>
        </AppLayout>
    )
}

export default Deposits


import React from 'react'
import {Table} from 'antd'
import './style.less'

function index({ dataSource, columns, onRow, pagination = false }) {
    return (
        <Table onRow={onRow} dataSource={dataSource} columns={columns} pagination={pagination}  />
    )
}

export default index

import React, { useEffect ,useState} from 'react'
import {Image, Select, Space, Typography} from 'antd'
import ApexChart from 'react-apexcharts'
import axios from "axios"

const {REACT_APP_BASE_URL} = process.env

// Country Select Component
function CurrencySelect({size, handleChange}) {

    // country flags to select from 
    const [countries, setCountries] = useState([])

    const fetchCountries = async () => {
        try {
          const token = window.localStorage.getItem('s_token')
          
          if(!token || typeof token === 'undefined') return

          const response =  await axios.get(`${REACT_APP_BASE_URL}admins/countries`, { headers: {
              'Authorization': `Bearer ${token}`
          } })
          if(response.status === 200 && response.data)
            setCountries(response.data)
        } catch (error) {
            setCountries([])
        }
    }


    useEffect(() => {
        fetchCountries()
    },[])

 

    const {Option} = Select
    return (
        <>
        <Select 
        dropdownMatchSelectWidth={false} 
        defaultValue={Array.isArray(countries) && countries.length > 0 ? countries[6].name : 'Select Country' } 
        style={{ width: size || 80  }} 
        showArrow 
        onChange={handleChange}
        >
          {
              countries.map(country => (
                <Option key={country._id} value={country._id}>
                    <Space direction='horizontal' size='small'>
                        <Image preview={false} src={country.flag} width={15} />
                        <Typography.Text>{country.name}</Typography.Text>
                        <Typography.Text>{country.currency}</Typography.Text>
                    </Space>
                </Option>
              )
              )
          }
        </Select>
      </>
    );
}


// Weight Select 
function WeightSelect() {

    function handleChange(value) {
        console.log(`selected ${value}`);
      }

    const {Option} = Select
    return (
        <>
        <Select defaultValue="OZ" style={{ width: 80 }} showArrow onChange={handleChange}>
          <Option value="OZ">OZ</Option>
          <Option value="G">G</Option>
          <Option value="KG">KG</Option>
        </Select>
       </>
    );
}


// Day Select
// Weight Select 
function DaySelect() {

    function handleChange(value) {
        console.log(`selected ${value}`);
      }

    const {Option} = Select
    return (
        <>
        <Select defaultValue="1" style={{ width: 80 }} showArrow onChange={handleChange}>
          <Option value="1">1 day</Option>
          <Option value="30">30 days</Option>
          <Option value="60" >60 days </Option>
        </Select>
       </>
    );
}




// Apex Chart Mini
function MiniChart(params) {

    // Data to render comes in as prop
    const kChartColor = "#810121";
    var options = {
       
        chart: {
        height: 35,
        width: 100,
        type: 'line',
        zoom: {
          enabled: false
        },
        sparkline: {
            enabled: true
        }
      },
      colors: [kChartColor],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        colors: kChartColor,
        width: 0.5
      },  
      grid: {
        show: false,
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },

      };

     const series = [
        {
          name: "mini",
          data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]  // where the data will be added
        }
        ]

    return (
        <>
            <ApexChart 
              series={series}
              options={options}
              type='line'
              height={35}
            />
        </>
    )
}


// Apex Chart 
function Chart() {
    const kChartColor = "#810121";
    const chartData =  {
        fill: { colors: kChartColor},
        options: {
            stroke: {
                width: 0.5,
            },
            colors: [kChartColor],
            chart: {
            id: "basic-bar",
            toolbar: false
            },
            grid: {
              show: true,
              borderColor: '#F5F7F8',
              strokeDashArray: 0,
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
              xaxis: {
                lines: {
                    show: false
                }
            },
            },
            xaxis: {
              type: 'category',
              categories:  ['Apr', 'May',  'Jun', 'Jul', 'Aug'],
              axisBorder: {
                show: false,
               },
              axisTicks: {
              show: false,
             },
          }
        },
        
        // Data on chart- YAxis
        series: [
        {
          name: "series-1",
          data: [1991,  1992,  1993,  1994, 1990]
        }
        ],
    };

    
    return (
        <>
            <ApexChart 
              series={chartData.series}
              options={chartData.options}
              type='line'
              width='100%'
            />
        </>
    )
}

export  { CurrencySelect, DaySelect, MiniChart, WeightSelect, Chart as default}

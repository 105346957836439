import React from 'react';
import up from '../icons/arrowUp.svg'
import { Divider } from '@material-ui/core';
 

function Activities(props) {
    return (

        <div>
            <div className='activityDiv'>
                <div className='activitySN'>
                    <img src={up} />
                    <p style={{
                        fontSize: '16px',
                        lineHeight: '19px',
                        /* identical to box height */
                        fontWeight: 500,
                        display: 'flex',
                        textAlign: 'right',
                        whiteSpace: 'nowrap',

                        color: ' #323030'
                    }}>{props.des}</p>
                </div>
                <div className='activityS1'>
                    <p style={{
                        fontSize: '16px',
                        lineHeight: '19px',
                        /* identical to box height */
                        fontWeight: 500,
                        display: 'flex',
                        textAlign: 'right',

                        color: ' #323030'
                    }}> {props.amount}</p>
                    <p style={{
                        marginTop: '-12%',
                        fontSize: '13px',
                        lineHeight: '15px',
                        /* identical to box height */
                        display: 'flex',
                        textAlign: 'right',

                        color: ' #A2A698'
                    }}> {props.date}</p>
                </div>

            </div>
            <Divider />

        </div>





    );
}

export default Activities;

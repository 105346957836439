import React, { useEffect, useReducer } from 'react'
import {Layout} from 'antd'
import HeaderNav from '../HeaderNav'
import Aside from '../Aside'
import axios from 'axios';
import { useHistory } from 'react-router';

let reducer = (state, action) => {
  switch (action.type) {
    case "SET_AUTH":
      return { token: action.token, user: action.data};
    case "LOG_OUT":
      return  null;
    default:
      return null
  }
};
const initialState = { user: {}, token: "" }
export const AuthContext = React.createContext(initialState);

const { REACT_APP_BASE_URL } = process.env;

function AuthProvider(props) {
  const router = useHistory();
  const {Content} = Layout;
const [state, dispatch] = useReducer(reducer, initialState);

const fetchUser = async () => {
    try {
       const { data } = await axios.get(REACT_APP_BASE_URL + `admins/users/${localStorage.getItem('s_user_id')}`, {
         
        headers: {
          Authorization : `Bearer ${localStorage.getItem('s_token')}`
        }
          
       })
        console.log(data.user);
        dispatch({type: 'SET_AUTH', data: data.user, token: localStorage.getItem('s_token') })

        
    } catch (error) {
        if (error && error.response && error.response.status) {
          if(error.response.status === '401' || error.response.status === '403'){
            localStorage.removeItem('s_token');
       
            router.push('/')
          }
        }
       
    }
  
   
}

useEffect(()=> {
    fetchUser();
},[])

return (
   <AuthContext.Provider value={{ state, dispatch }}>
       <div style={{ display: 'flex', flexWrap: 'nowrap'}}>
            {/* Sidebar */}
            <Aside />
            <Layout>
                {/* NavBar */}
                <HeaderNav user={state}/>
                {props.SubMenu}
                {/* SideBar */}

                {/* Content */}
                <Layout>
                    <Content user={state} className='bit-main'>
                        {props.children}     
                    </Content>
                </Layout>
            </Layout>
        </div>
    </AuthContext.Provider>
  );
}

export  default  AuthProvider 


import React from 'react';
import './style.less'


function SearchBar() {
    return (
        <div>
            <input type='text' placeholder='search' className='searchBar' />

        </div>
    );
}

export default SearchBar;

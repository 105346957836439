import React, { createContext, useContext, useReducer} from 'react'
import {TOGGLE_MENU} from './type'

const GlobalContext = createContext(null)

const globalReducer = (state, action) => {
    switch (action.type) {
        case TOGGLE_MENU:
           return {
               ...state,
               collapsed: action.collapsed
           }
    
        default:
            return state
    }
}


export const GlobalProvider = ({children}) => {
    const [state, dispatch] = useReducer(globalReducer,{
        collapsed: true
    })

    return (
        <GlobalContext.Provider value={{ state, dispatch }}>
            {children}
        </GlobalContext.Provider>
    )
}


export const useGlobalContext = () => useContext(GlobalContext)
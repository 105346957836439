import React, { createContext, useContext, useReducer} from 'react'
import {FETCH_BUY_REQUEST, FETCH_SELL_REQUEST , FETCH_ARRAY, FETCH_TRANSACTIONS, FETCH_RATES} from './type'

const RequestContext = createContext(null)

const RequestReducer = (state, action) => {
    switch (action.type) {
        case FETCH_BUY_REQUEST:
          return  {
                ...state,
                ...action.buyRequests
            }
        case FETCH_SELL_REQUEST:
          return {
              ...state,
              ...action.sellRequests
          }
          //wallet
          case FETCH_ARRAY:
            return {
                ...state,
               data: action.data
            }
        case FETCH_TRANSACTIONS:
            return {
                ...state,
                data: action.transactions
            }
        case FETCH_RATES:
            return {
                ...state,
                data: action.rates
            }
        
        default:
            return state
    }
}


export const RequestProvider = ({children}) => {
    const [state, dispatch] = useReducer(RequestReducer, {
        buyRequests: [],
        sellRequests: [],
        data: [],
        transactions: [],
        rates: []
    })

    return (
        <RequestContext.Provider value={{ state, dispatch }}>
            {children}
        </RequestContext.Provider>
    )
}


export const useRequestContext = () => useContext(RequestContext)
import React from 'react'
import {Button, Col, DatePicker, Input, Row, Select} from 'antd'
import './style.less'


export function PageSizeSelect({handleChange}) {
  

    const {Option} = Select
    return (
        <>
        <Select defaultValue="10" style={{ width: 120 }} showArrow onChange={handleChange}>
          <Option value="10">10/Page</Option>
          <Option value="20">20/Page</Option>
          <Option value="50">50/Page</Option>
          <Option value="100">100/Page</Option>
        </Select>
       </>
    );
}

export function FilterByStatus({ defaultValue, options, handleChange }) {
    // function handleChange(value) {
    //     console.log(`selected ${value}`);
    //   }

    const {Option} = Select
    return (
        <>
        {
         Array.isArray(options) && options.length > 0 
         ?
        <Select defaultValue='' style={{ width: 120 }} showArrow onChange={handleChange}>
            <Option disabled value=''>Filter by: Type</Option>
            {
                Array.isArray(options) 
                &&
                options.map((option, index) => (
                    <Option key={index}  value={option.value}>{option.label}</Option>
                )
                )    

            }
        </Select>

            :
        <Select defaultValue='No Status' disabled style={{ width: 120 }} showArrow onChange={handleChange}>
             {/* <Option value="paid">No</Option> */}
        </Select>
        }
       </>
    );
}

export function SelectDatePickers({ onStartDate, onEndDate }) {
    return (
        <Row align='middle'>
            <Col lg={24} style={{display:'flex'}}>
                <DatePicker onChange={onStartDate} placeholder='Start date' style={{marginRight: '1rem'}} />
                <DatePicker onChange={onEndDate}  placeholder='End date' />
            </Col>
        </Row>
    )
}

export function Search({ onSearch }) {
    const{Search} = Input

    return (
        <Search placeholder='Search' onChange={(event) => onSearch(event.target.value)} onSearch={onSearch} />
    )
}

export function AddWallet({ routeToAddWallet }) {
    return (
        <Button type='primary' onClick={routeToAddWallet} style={{ color: 'white', borderRadius: '8px'}}>
            Add Wallet
        </Button>
    )
}


function index({ pageSizeFilter, statusFilter, datePickerFilter, searchFilter, addWallet }) {
    return (
        <Row style={{padding: '1rem', paddingRight: '2rem'}} align='middle' justify='space-between'>
            <Col lg={addWallet && addWallet.enabled ? 0 : 12}>
                    <Row>
                        {/* PageSizeFilter */}
                        {
                            pageSizeFilter && pageSizeFilter.enabled
                            ?
                            <Col lg={6}>
                              {pageSizeFilter.render}
                           </Col>
                           : 
                           ''
                        }
                        {/* End PageSize Filter */}

                        {/* Status Filter */}
                        {
                           statusFilter && statusFilter.enabled
                           ?
                           <Col lg={6}>
                            {statusFilter.render}
                           </Col>
                           :
                           ''
                        }
                        {/* End Status Filter */}

                        {/* DatePickers */}
                         {
                             datePickerFilter && datePickerFilter.enabled
                             ?
                             <Col lg={12}>
                              {datePickerFilter.render}
                             </Col>
                             :
                             ''
                         }
                        {/* End Date Pickers */}

                        
                        {/* <Col lg={6}>
                            D
                        </Col> */}
                </Row>
            </Col>

            {/* Search */}
            <Col lg={6}>
                {searchFilter}
            </Col>
            {
                addWallet
                &&
                <Col lg={3}>
                {addWallet && addWallet.render}
                </Col>
            }
        </Row>
    )
}

export default index

import React from 'react'
import {Image, Menu} from 'antd'
import {Link, useHistory} from 'react-router-dom'

// icons
import {
    Acc, 
    Buy, 
    Dash, 
    Desposit, 
    Lay, 
    Logout,
    Rates,
    Sell,
    Trans,
    Users,
    Wallet,
    Withdrawl
} from '../../icons'


const asideMenu = [
    {
        path: '/dashboard',
        icon: Dash,
        label: 'Dashboard'
    },
    {
        path: '/withdrawals',
        icon: Withdrawl,
        label: 'Withdrawals'
    },
    {
        path: '/deposit',
        icon: Desposit,
        label: 'Deposits'
    },
    {
        path: '/buy',
        icon: Buy,
        label: 'Buy'
    },
    {
        path: '/sell',
        icon: Sell,
        label: 'Sell'
    },
    {
        path: '/transactions',
        icon: Trans,
        label: 'Transactions'
    },
    {
        path: '/layaways',
        icon: Lay,
        label: 'Layaways'
    },
    {
        path: '/wallets',
        icon: Wallet,
        label: 'Wallets'
    },
    {
        path: '/rates',
        icon: Rates,
        label: 'Rates'
    },
    {
        path: '/users',
        icon: Users,
        label: 'Users'
    },
    {
        path: '/account',
        icon: Acc,
        label: 'Account'
    },
    {
        path: '/logout',
        icon: Logout,
        label: 'Logout'
    },
]

function AsideMenuItem({ index, icon, label, path, ...rest}) {
    const history = useHistory()
    const isActive =  history.location.pathname.includes(path)
    const activeClass = isActive ?  ' bit-menu-item-active-'+ index : ''

  return  ( 
  <>
    {
    // asideMenu.map((m, index) => 
        <Link to={path}  className={'bit-menu-item  bit-menu-item-'+ index +  activeClass}>
            <Menu.Item key={index} {...rest} icon={<Image src={icon} width={20} />} >
                {label}
            </Menu.Item>
         </Link> 
        // )
  
    }
  </>
    )
}

function AsideMenu() {
    return (
        <Menu  mode="vertical" defaultSelectedKeys={['-2']} >
            {
                asideMenu.map((item, index) => (
                    <AsideMenuItem 
                     key={index} 
                     index={index.toString()}
                     icon={item.icon} 
                     label={item.label} 
                      path={item.path}
                     />
                ))
            }
        </Menu>
    )
}

export default AsideMenu

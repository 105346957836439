import React from 'react'
import {Avatar, Col, Row, Space, Typography } from 'antd'
import './style.less'

function RenderTotal({title = '', total = '', icon='' }) {

    function renderTitle(title) {
        return (
            <div className='bit-total-title'>
            <Row>
                <Col span={24}>
                    <Space size='middle'>
                       <Avatar className='bit-avatar' icon={icon} />
                       <Typography.Text>{title}</Typography.Text>
                    </Space>
                </Col>
            </Row>
           </div>
        )
    }


    function renderTotal(total) {
        return (
            <div className='bit-total'>
                <Typography.Title level={4}>{total || 0}</Typography.Title>
            </div>
        )
    }



    return (
        <div className='bit-col' style={{flexDirection: 'column'}}>
                {renderTitle(title)}
                {renderTotal(total)}
        </div>
    )
}

export default RenderTotal

import React, { useEffect, useState } from 'react'
import {Button, Col, Layout, Row, Select, Space} from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import axios from 'axios'
import {format, parseISO} from 'date-fns'
import { useRequestContext } from '../Contexts/RequestContext'
import AppLayout from '../Components/Layouts'
import BitTable from '../Components/BitTable'
import PageTitle from '../Components/PageTitle'
import SearchFilter, { FilterByStatus, PageSizeSelect, Search } from '../Components/SearchFilter'
import { FETCH_BUY_REQUEST, FETCH_TRANSACTIONS } from '../Contexts/type'

const {REACT_APP_BASE_URL} = process.env


// // PageFilters
// function PageSizeSelect(params) {
//     function handleChange(value) {
//         console.log(`selected ${value}`);
//       }

//     const {Option} = Select
//     return (
//         <>
//         <Select defaultValue="10" style={{ width: 120 }} showArrow onChange={handleChange}>
//           <Option value="10">10/Page</Option>
//           <Option value="20">20/Page</Option>
//           <Option value="50">50/Page</Option>
//           <Option value="100">100/Page</Option>
//         </Select>
//        </>
//     );
// }

function Transaction() {
   const {Content} = Layout
   const {state, dispatch } =  useRequestContext()
   const [buyRequests, setBuyRequests] = useState([])
   const [tableData, setTableData] = useState([])

//   Filter States
   const [pageSize, setPageSize] = useState(10)
   const [statusFilter]= useState('Both')

   async function getBuyRequest() {

    if(state && Array.isArray(state.transactions) && state.transactions.length > 0) {
        const br = state.transactions.map(row => ({
            key: row._id.toString(),
            location: row.delivery && row.delivery.address ? row.delivery.address  : '',
            date: format(parseISO(row.createdAt), 'MM-dd-yyyy') ,
            time: format(parseISO(row.createdAt), 'HH:mm:ss aaaa'),
            ...row
        })
        );
         setBuyRequests(br)
         setTableData(br)
         return;
    }
    const url = REACT_APP_BASE_URL + 'admins/transactions'
   try {
    const token = window.localStorage.getItem('s_token')
    if(!token || typeof token === 'undefined' || token === null) return

    const response = await axios.get(url,{
    headers: {
        'Authorization': `Bearer ${token}`
    }
    })
  console.log(response.data)
    if(response.status === 200 && response.data && Array.isArray(response.data.transactions)) {
        let br = response.data.transactions

        br = br.map(row => ({
            key: row._id.toString(),
            location: row.delivery && row.delivery.address ? row.delivery.address  : '',
            date: format(parseISO(row.createdAt), 'MM-dd-yyyy') ,
            time: format(parseISO(row.createdAt), 'HH:mm:ss'),
            ...row
        })
        );
        // dispatch
        dispatch({
            type: FETCH_TRANSACTIONS,
            transactions: response.transactions
        })

      
        setBuyRequests(br)
        setTableData(br)
    }

   } catch (error) {

    // alert an error message
     setBuyRequests([])
     setTableData([])
   } 
}

useEffect(() => {
    getBuyRequest()
}, [])


/*  ------------
----------------PAGE FILTERS ----------
--------------------------------------- */

// Handle Page SizeChange
function handleChange(value) {
    console.log(`selected ${value}`);
    setPageSize(+value)
  }


//   Handle Status Filter
const statusOptions = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'Gold',
        value: 'Gold'
    },
    {
        label: 'Silver',
        value: 'Silver'
    }
] 

function handleStatusChange(value) {
    if(value.toLowerCase() !== 'all'){
        const ds = buyRequests.filter((br) => typeof br.type === 'string' && br.type.toLowerCase() === value.toLowerCase() )
        setTableData(ds)
        return
    }
    setTableData(buyRequests)
      
}


// Name Search
function onSearch(value) {
    console.log(value);
}

const btnStyle = {paddingRight:'.3rem', paddingLeft:'.3rem', backgroundColor: '#F5F5F5', display: 'flex', alignItems: 'center'} 
const columns = [
    {
        key: 'name',
        dataIndex: 'userId',
        title: 'Name'
    },
    {
        key: 'date',
        dataIndex: 'date',
        title: 'Date'
    },
    {
        key: 'time',
        dataIndex: 'time',
        title: 'Time'
    },
    {
        key: 'type',
        dataIndex: 'entryType',
        title: 'Type'
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: 'Amount'
    },
    // {
    //     key: 'location',
    //     dataIndex: 'location',
    //     title: 'Location'
    // },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
     
    },
]

    return (
        <AppLayout>
            <Content>
                {/* Page Header */}
                <PageTitle title='Transactions' />

                {/* Page Content */}
                <div className='bit-my bit-col' style={{flexDirection: 'column'}}>
                    <SearchFilter 
                      pageSizeFilter={{
                        enabled: true,
                        render: <PageSizeSelect handleChange={handleChange} />
                      }} 
                      statusFilter={{
                          enabled: true,
                          render: <FilterByStatus defaultValue={statusFilter} options={statusOptions} handleChange={handleStatusChange} />
                      }}
                      
                      searchFilter= {<Search  onSearch={onSearch} />}
                     
                    />
                <Row >
                    <Col style={{width: '100%'}}>
                        <BitTable 
                          dataSource={tableData} 
                          columns={columns} 
                          pagination={{ 
                              total: tableData.length,
                              // showSizeChanger: true,
                              // showQuickJumper: true,
                              showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
                              defaultPageSize: pageSize,
                              pageSize,
                              defaultCurrent: 1
                            }} 
                            />
                    </Col>
                </Row>
              </div>
            </Content>
        </AppLayout>
    )
}

export default Transaction

import React, { useEffect, useState } from 'react'
import {Button, Col, Image, Layout, Modal, Row, Select, Space} from 'antd'
import {useHistory} from 'react-router-dom'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import axios from 'axios'
import {format, parseISO} from 'date-fns'
import { useRequestContext } from '../Contexts/RequestContext'
import AppLayout from '../Components/Layouts'
import BitTable from '../Components/BitTable'
import PageTitle from '../Components/PageTitle'
import SearchFilter, { AddWallet, FilterByStatus, PageSizeSelect, Search } from '../Components/SearchFilter'
import { FETCH_ARRAY, FETCH_BUY_REQUEST } from '../Contexts/type'

const {REACT_APP_BASE_URL} = process.env


// // PageFilters
// function PageSizeSelect(params) {
//     function handleChange(value) {
//         console.log(`selected ${value}`);
//       }

//     const {Option} = Select
//     return (
//         <>
//         <Select defaultValue="10" style={{ width: 120 }} showArrow onChange={handleChange}>
//           <Option value="10">10/Page</Option>
//           <Option value="20">20/Page</Option>
//           <Option value="50">50/Page</Option>
//           <Option value="100">100/Page</Option>
//         </Select>
//        </>
//     );
// }

function Wallet() {
   const {Content} = Layout
   const history = useHistory()

   const {state, dispatch } =  useRequestContext()
   const [buyRequests, setBuyRequests] = useState([])
   const [tableData, setTableData] = useState([])
   const [wallet, setWallet] = useState(undefined);
   const [isModalVisible, setIsModalVisible] = useState(false);

//   Filter States
   const [pageSize, setPageSize] = useState(10)
   const [statusFilter]= useState('Both')

   async function getBuyRequest() {

    if(state && Array.isArray(state.data) && state.data.length > 0) {
        const br = state.data.map(row => ({
            key: row._id.toString(),
            location: row.delivery && row.delivery.address ? row.delivery.address  : '',
            date: format(parseISO(row.createdAt), 'MM-dd-yyyy') ,
            time: format(parseISO(row.createdAt), 'HH:mm:ss aaaa'),
            ...row
        })
        );
         setBuyRequests(br)
         setTableData(br)
         return;
    }
    const url = REACT_APP_BASE_URL + 'admins/wallets'
   try {
    const token = window.localStorage.getItem('s_token')
    if(!token || typeof token === 'undefined' || token === null) return

    const response = await axios.get(url,{
    headers: {
        'Authorization': `Bearer ${token}`
    }
    })
  console.log(response.data)
    if(response.status === 200 && response.data && Array.isArray(response.data.wallets)) {
        let br = response.data.wallets

        br = br.map(row => ({
            key: row._id.toString(),
            location: row.delivery && row.delivery.address ? row.delivery.address  : '',
            date: format(parseISO(row.createdAt), 'MM-dd-yyyy') ,
            time: format(parseISO(row.createdAt), 'HH:mm:ss'),
            ...row
        })
        );
        // dispatch
        dispatch({
            type: FETCH_ARRAY,
            data: response.data
        })

      
        setBuyRequests(br)
        setTableData(br)
    }

   } catch (error) {

    // alert an error message
     setBuyRequests([])
     setTableData([])
   } 
}

useEffect(() => {
    getBuyRequest()
}, [])


/*  ------------
----------------PAGE FILTERS ----------
--------------------------------------- */

// Handle Page SizeChange
function handleChange(value) {
    console.log(`selected ${value}`);
    setPageSize(+value)
  }


//   Handle Status Filter
const statusOptions = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'Gold',
        value: 'Gold'
    },
    {
        label: 'Silver',
        value: 'Silver'
    }
] 

function handleStatusChange(value) {
    if(value.toLowerCase() !== 'all'){
        const ds = buyRequests.filter((br) => typeof br.type === 'string' && br.type.toLowerCase() === value.toLowerCase() )
        setTableData(ds)
        return
    }
    setTableData(buyRequests)
      
}


// Name Search
function onSearch(value) {

    const found =  buyRequests.filter(b => b.title.toLowerCase().includes(value.toLowerCase()) )
  
    if(found.length > 0) {
        setTableData(found)
        return
    } 
    else
    if(value.length === 0) {
        setTableData(buyRequests)
    }
    else
    if(found.length === 0) {
        setTableData([])
        return
    }
    
}

// Route to AddWallet
function routeToAddWallet() {
    history.push('/wallets/add')
}

const btnStyle = {
    paddingRight:'.9rem', 
    paddingLeft:'.9rem', 
    backgroundColor: '#F5F5F5', 
    display: 'flex', 
    alignItems: 'center',
    border: 'none'
} 
const columns = [
    {
        key: 'country',
        dataIndex: 'flag',
        title: 'S/N',
  
    render: (text, record) => (
        <Space size="middle">
          <Image src={text}  height='35px' width='55px'/>        
        </Space>
      ),
    },
    {
        key: 'wallet',
        dataIndex: 'title',
        title: 'Wallet'
    },
    {
        key: 'currency',
        dataIndex: 'currencyCode',
        title: 'Currency'
    },
    {
        key: 'date',
        dataIndex: 'date',
        title: 'Date'
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
           
             <Button style={{ ...btnStyle, paddingLeft: '15px !important', paddingRight: '15px !important'}} shape="round" icon={<img alt='' src='icons/edit.svg' className='mr-2' />} size={10}>
              Edit
             </Button>
             <Button  onClick={() => handleModalVisible(record)} style={btnStyle} shape="round" icon={<CloseOutlined />} size={10}>
              Delete
             </Button>
            
          </Space>
        ),
      },
]


//  Delete Modal
const handleModalVisible = (wallet) => {
    setWallet(wallet)
    setIsModalVisible(true)
}
const handleOk = () => {
    console.log(wallet)
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

function renderDeleteModal() {
    return (
        <Modal title={wallet &&  'Delete ' + wallet.title} visible={isModalVisible} footer={null} width={400}>
        <p>What happens when you delete a wallet?.</p>
        <p>The wallet will no longer be available to the admin platform</p>
        <div style={{ width: '100%', display:'flex', justifyContent:'space-around' }} >
            <Button onClick={handleCancel} style={{ borderRadius: '5px' }} block type='default'>No</Button>
              <div style={{width: '20px'}} />
            <Button onClick={handleOk} style={{ borderRadius: '5px' }} block type='primary'>Yes</Button>
        </div>
      </Modal>
    )
}

    return (
        <AppLayout>
            <Content>
                {/* Page Header */}
                <PageTitle title='Wallets' />

                {/* renderModal */}
                {renderDeleteModal()}

                {/* Page Content */}
                <div className='bit-my bit-col' style={{flexDirection: 'column'}}>
                    <SearchFilter 
                      pageSizeFilter={{
                        enabled: false,
                        render: <PageSizeSelect handleChange={handleChange} />
                      }} 
                      statusFilter={{
                          enabled: false,
                          render: <FilterByStatus defaultValue={statusFilter} options={statusOptions} handleChange={handleStatusChange} />
                      }}
                      
                      searchFilter= {<Search  onSearch={onSearch} />}
                      addWallet ={{
                          enabled: true,
                          render: <AddWallet routeToAddWallet={routeToAddWallet} />
                      }}
                     
                    />
                <Row >
                    <Col style={{width: '100%'}}>
                        <BitTable 
                          dataSource={tableData} 
                          columns={columns} 
                          pagination={{ 
                              total: tableData.length,
                              // showSizeChanger: true,
                              // showQuickJumper: true,
                              showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
                              defaultPageSize: pageSize,
                              pageSize,
                              defaultCurrent: 1
                            }} 
                            />
                    </Col>
                </Row>
              </div>
            </Content>
        </AppLayout>
    )
}

export default Wallet;

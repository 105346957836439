import React from 'react';
import Divider from '@material-ui/core/Divider';


function ActivityCard(props) {
    return (
        <div style={{
            background: '#FFFFFF',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            width: '97%',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '1px 2%',
                marginTop: '2.5%',
                width: '85%',

            }}>
                <div>
                    <p style={{
                        fontFamily: 'quicksand',
                        fontStyle: 'normal',
                        fontWeight:500,
                        fontSize: '18px',
                        lineLeight: '21px',

                        color: '#323232',
                    }}>{props.header}</p>
                </div>
                <div>

                    <div> {props.subheader}</div>
                </div>

            </div>
            <Divider />
            <div style={{


            }}>

                {props.body}


            </div>

           


        </div >
    );
}

export default ActivityCard;

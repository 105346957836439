import React from 'react'
import {Layout,  Row, Col, Image, Typography} from 'antd'
import { useGlobalContext } from '../../Contexts/GlobalContext'
import AsideMenu from './AsideMenu'
import './style.less'
import Sanu from '../../icons/sanu.svg'
import { TOGGLE_MENU } from '../../Contexts/type'




function Aside() {
   const {state, dispatch} = useGlobalContext()
    const {Sider} = Layout 
    const {Paragraph} = Typography
    return (
        <Sider 
         collapsible 
         collapsedWidth={0}  
         breakpoint="sm" 
         collapsed={state.collapsed} 
         onBreakpoint={(broken) => {
            dispatch({
                type: TOGGLE_MENU,
                collapsed: broken
            })
         }} 
         trigger={null}  
         className='bit-aside'
         >
           <Row align='middle' justify='center' style={{height: '120px'}}>
                <Col style={{position:'relative'}}>
                    <div className='bit-brand'>
                        <Image preview={false} src={Sanu} width={98} />
                        <Paragraph className='bit-brand-title'>ADMIN </Paragraph> 
                    </div> 
                </Col>
            </Row>
            <Row>
                <AsideMenu />
            </Row>
        </Sider>
    )
}

export default Aside

import React from 'react';
import sample from '../icons/sample.svg'

function UserCard(props) {
    return (
        <div className='userCardDiv'>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around'
            }}>
                <img className='userCardImg' src={props.img} />
                <div>
                    <p className='userCardST'>{props.name}</p>

                </div>
            </div>
            <div>
                <h1 className='userCardNum'>{props.amount}</h1>

            </div>
        </div>
    );
}

export default UserCard;

import React, { useContext, useEffect, useReducer, useState } from 'react'
import {Button, Col, Layout, Row, Space} from 'antd'
import AppLayout, { AuthContext } from '../Components/Layouts';
import AddAdmin from '../../src/Components/Account/AddAdmin'
import Admins from '../Components/Account/Admins';
import NewAdmin from '../Components/Account/NewAdmin';


const Account = (props) => {
    const {Content} = Layout
    const [activeMenu, setActiveMenu] = useState('Admin')
    const [pageTitle, setPageTitle] = useState('Admin')
    const [queryName, setQueryName ] = useState('Admin');
    const queryNameFunc = (val) => {
        setQueryName(val)
    }

//     useEffect(()=> {
// FetchData()
//     }, [queryName])

    function renderSubMenu() {
        const subMenuStyle = {
            backgroundColor: 'white', 
            borderTopStyle: 'solid',
            borderTopColor: 'rgb(243 243 243)',
            borderTopWidth: '1px',
            padding: '1rem', 
            paddingLeft: '40px',
            paddingRight: '40px'
        }
    
        const btnStyle= {
            border:'none',
            boxShadow: 'none',
            color: '#94979B',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem'
        }
    
        const activeStyle = {
            backgroundColor: 'rgba(128, 0, 32, 0.05)',
            border: 'none',
            color: '#800020',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem'
        }
    
        function changeMenu(menu) {
            setActiveMenu(menu)
            setPageTitle(menu)
        }
    
      
        return (
            <Row style={subMenuStyle}>
                <Col span={6}>
                    <Space direction='horizontal'>
                    <Button onClick={() =>{ queryNameFunc('Admin'); changeMenu('Admin') } } style={activeMenu === 'Admin' ? activeStyle : btnStyle} shape="round" size='middle'>
                      Admin
                    </Button>
                    <Button onClick={() => { queryNameFunc('All Admins'); changeMenu('All Admins')} } style={activeMenu === 'All Admins' ? activeStyle : btnStyle}  shape="round" size='middle'>
                     All Admins
                    </Button>

                    <Button onClick={() => { queryNameFunc('New Admin'); changeMenu('New Admin')} } style={activeMenu === 'New Admin' ? activeStyle : btnStyle}  shape="round" size='middle'>
                     New Admins 
                    </Button>
                    </Space>
                </Col>
            </Row>
        )
    }
    // const [context, setContext ] = useReducer(AuthContext);
    return (
        <AppLayout SubMenu={renderSubMenu()}>
            <Content>
          
              { queryName === 'Admin' && <AddAdmin /> }
              { queryName === 'All Admins' && <Admins/> }
              { queryName === 'New Admin' && <NewAdmin/> }
            </Content>
        </AppLayout>
    )
}

export default Account;
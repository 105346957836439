import React, {useEffect, useState} from 'react'
import {Breadcrumb, Button, Col, Form, Image, Input, Layout,Modal, notification, Row, Typography, Upload} from 'antd'
import axios from 'axios'
import {CloudUploadOutlined} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import AppLayout from '../Layouts'
import PageTitle from '../PageTitle'
import {Upload as UploadIcon} from '../../icons'


const {REACT_APP_BASE_URL} = process.env

function NewAdmin() {
   const [ user , setUser] = useState({})
    const fetchUser = async () => {
        try {
           const { data } = await axios.get(REACT_APP_BASE_URL + `admins/users/${localStorage.getItem('s_user_id')}`, {
             
            headers: {
              Authorization : `Bearer ${localStorage.getItem('s_token')}`
            }
              
           })
            console.log(data.user);
            // dispatch({type: 'SET_AUTH', data: data.user, token: localStorage.getItem('s_token') })
            setUser(data.user)
            form.setFields({firstName: data.user.firstName})
            
        } catch (error) {
            // if (error && error.response && error.response.status) {
            //   if(error.response.status === '401' || error.response.status === '403'){
            //     localStorage.removeItem('s_token');
           
            //     router.push('/')
            //   }
            // }
           
        }
      
       
    }
    useEffect(()=> {
        fetchUser()
    },[])
    const {Content} = Layout
    const [form] = Form.useForm()
    const [files, setFiles] = useState([])
    const [previewImage, setPreviewImage] = useState('')
    // const [previewVisible, setPreviewVisible] = useState(false)

    const buttonStyle = { borderRadius: '8px', color: 'white', marginTop: '2rem',  width: '100%'}
    const inputStyle = { borderRadius: '8px'}
    const labelStyle = { marginBottom: 0}


    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }


    const handleFileUpload = ({file}) => {
        
        if(file && file.status !== 'error'){
            setFiles([file])
            handlePreview(file)
        }
    }
    // const handleCancel = () => setPreviewVisible(false)

    async function handlePreview(file) {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview)
        // setPreviewVisible(true)
        // previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    };


    // HandleForm Submission
    async function handleOnSubmit(values) {
       
        if(files.length === 1 && values.title.length > 0) {
            try {
               const response =  await  axios.post(`${REACT_APP_BASE_URL}/admins/wallets`, {
                    title: values.title,
                    countryCode: values.countryCode,
                    currencyCode: values.currencyCode
                })

                if(response.status === 200){
                    notification.success({
                        message: 'New wallet Added'
                    })
                }
                form.resetFields()
            } catch (error) {
                notification.error({
                    message: 'Wallet could not be added'
                })
            }
        }
    }
  
  
    function renderForm(user) {

        // api takes title, countryCode, currencyCode
        return (
            <Form
            form={form}
            layout='vertical'
            size='large'
            initialValues={{
                firstName: user && user.firstName && user.firstName,
                lastName: user && user.lastName && user.lastName,
                email: user && user.email && user.email,
                role: 'Admin'
            }}
            onFinish={handleOnSubmit}
            >
                 <Form.Item name='firstName' label="Full name" className='bit-form-label' style={labelStyle} >
                     {/* {JSON.stringify(user.firstName)} */}
                    <Input placeholder="Enter first name" style={inputStyle} />
                </Form.Item>

                <Form.Item name='lastName' label="Full name" className='bit-form-label' style={labelStyle} >
                    <Input placeholder="Enter last name" style={inputStyle} />
                </Form.Item>
                 <Form.Item name='email' label="Email Address" className='bit-form-label' style={labelStyle} >
                    <Input placeholder="Enter email address" style={inputStyle} />
                </Form.Item>
                 <Form.Item name='role' label="Role" className='bit-form-label' style={labelStyle} >
                    <Input value="Admin" disabled  placeholder="Enter password" style={inputStyle} />
                </Form.Item>

                <Button type='primary' htmlType='submit' style={buttonStyle}>Save</Button>
            </Form>
        )
    }

    // useEffect(()=> {
    //     renderForm(user)
    // },[user])

    function renderFileUpload() {
        
        return (
            <div className='bit-upload' style={{
                marginLeft: '5rem',
                width: '200px',
                height: '200px'
            }}>
            <Upload 
            //  accept="image/jpeg"
             action=''
             fileList={files}
            //  listType='picture-card'
             onPreview={handlePreview}
             onChange={handleFileUpload}
             onRemove={(file) => console.log(file)}
            >
                <div style={{
                    // backgroundColor: ' #F4F4F4',
                    borderRadius: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Image src={previewImage ? previewImage : UploadIcon} preview={false} />
                    <Button type='link'
                    style={{ color: '#800020', textAlign:"center", marginTop: '1rem' }}>
                        Upload Currency Image 
                    </Button>
                </div>
            </Upload>
            
            {/* <Modal
            visible={previewVisible}
            // title={previewTitle}
            footer={null}
            onCancel={handleCancel}
            >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal> */}
            </div>
        )
    }

    return (
        // <AppLayout>
            <Content style={{height: '90%'}}>
                {/* Page Header */}
                {/* <PageTitle title='Wallets' /> */}
                {/* <Breadcrumb separator='>'>
                     <Breadcrumb.Item>
                        <Link to='/wallets' style={{color: '#800020',  }}>Wallets</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                         <Typography.Text style={{ color: '#828282' }}>Add wallet</Typography.Text>   
                     </Breadcrumb.Item>
                </Breadcrumb> */}
                
                  {/* Page Content */}
                  <div className='bit-my  bit-col' style={{flexDirection: 'column', maxHeight: '713px', height: '100%'}}>
                      <Row  style={{ padding: '1.5rem', paddingLeft: '3rem'}}>
                          <Col sm={24} lg={8}>
                              {/* RenderForm */}
                              { renderForm()}
                          </Col>
                          <Col sm={24} lg={12}>
                              {/* RenderUpload */}
                              {renderFileUpload()}
                          </Col>
                      </Row>
                  </div>
            </Content>
        // </AppLayout>
    )
}

export default NewAdmin

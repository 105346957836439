/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useHistory } from "react-router"

const Logout = () => {
    const history = useHistory()
    useEffect(()=> {
        localStorage.removeItem('s_token')
        history.push('/')
    },[])
    return (
        <></>
    )
}

export default Logout;
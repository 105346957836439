import React from 'react'
import { Typography } from "antd";

function index({ title }) {

    return (
            <Typography.Title level={4}>{title}</Typography.Title>
    )
}

export default index

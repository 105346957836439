import React, { useEffect, useState } from 'react'
import {Button, Col, Layout, Modal, Row, Space, Typography} from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import axios from 'axios'
import {format, parseISO} from 'date-fns'
import { useRequestContext } from '../Contexts/RequestContext'
import AppLayout from '../Components/Layouts'
import BitTable from '../Components/BitTable'
import { CurrencySelect } from '../Components/Chart'
import PageTitle from '../Components/PageTitle'
import SearchFilter, { FilterByStatus, PageSizeSelect, Search, SelectDatePickers } from '../Components/SearchFilter'
import { FETCH_BUY_REQUEST, FETCH_RATES } from '../Contexts/type'

const {REACT_APP_BASE_URL} = process.env


// // PageFilters
// function PageSizeSelect(params) {
//     function handleChange(value) {
//         console.log(`selected ${value}`);
//       }

//     const {Option} = Select
//     return (
//         <>
//         <Select defaultValue="10" style={{ width: 120 }} showArrow onChange={handleChange}>
//           <Option value="10">10/Page</Option>
//           <Option value="20">20/Page</Option>
//           <Option value="50">50/Page</Option>
//           <Option value="100">100/Page</Option>
//         </Select>
//        </>
//     );
// }

function Rates() {
   const {Content} = Layout
   const {state, dispatch } =  useRequestContext()

   const ratesType = [
    {
        query: 'gold_bar',
        label: 'Gold bars'
    },
    {
        query: 'gold_medallion',
        label: 'Gold medallions(oz)'
    },
    {
        query: 'gold_savings_product',
        label: 'Gold saving products (grams)'
    },
    {
        query: 'gold_cast_wire',
        label: 'Gold cast wire (inches)'
    },
    {
        query: 'gold_square_wire',
        label: 'Gold square wire (mm)'
    },
    {
        query: 'gold_plate_and_sheet',
        label: 'Gold plates & sheets'
    },
    {
        query: 'gold_alloys',
        label: 'Gold Alloys'
    },
    {
        query: 'silver_bar',
        label: 'Silver bars'
    },
    {
        query: 'silver_medallion',
        label: 'Silver medallions (oz)'
    },
]

   const [buyRequests, setBuyRequests] = useState([])
   const [tableData, setTableData] = useState([])
   const [tableColumn, setTableColumn] = useState()
   const [pageTitle] = useState('Rates')
   const [activeMenu, setActiveMenu] = useState(ratesType[0].query)
   const [rate, setRate] = useState(undefined)
   const [isModalVisible, setIsModalVisible] = useState(false)

//   Filter States
   const [pageSize, setPageSize] = useState(10)
   const [statusFilter]= useState('All')



/*  ------------
----------------PAGE FILTERS ----------
--------------------------------------- */

// Handle Page SizeChange
function handleChange(value) {
    console.log(`selected ${value}`);
    setPageSize(+value)
  }


//   Handle Status Filter
const statusOptions = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'Pending',
        value: 'Pending'
    },
    {
        label: 'Paid',
        value: 'Paid'
    },
    {
        label: 'Declined',
        value: 'Declined'
    }
] 

function handleStatusChange(value) {
    if(value.toLowerCase() !== 'all'){
        const ds = buyRequests.filter((br) => typeof br.type === 'string' && br.type.toLowerCase() === value.toLowerCase() )
        setTableData(ds)
        return
    }
    setTableData(buyRequests)
      
}

// Country Select
function onHandleCountrySelect(value) {
       console.log(`selected ${value}`);
}

// Name Search
function onSearch(value) {
    console.log(value);
}


// Date Filters
function onStartDate(date, dateString) {
    console.log(date, dateString)
}

function onEndDate(date, dateString) {
    console.log(date, dateString)
}

const btnStyle = {paddingRight:'.3rem', paddingLeft:'.3rem', backgroundColor: '#F5F5F5', display: 'flex', alignItems: 'center'} 
let columns = [
    {
        key: 'displayName',
        dataIndex: 'displayName',
        title: 'Display Name'
    },
    {
        key: 'metalType',
        dataIndex: 'metalType',
        title: 'Metal Type'
    },
    {
        key: 'percent',
        dataIndex: 'percent',
        title: 'Percent'
    },
    {
        key: 'type',
        dataIndex: 'type',
        title: 'Type',
        render: (text,record) => (
            <Typography.Text>{record && record.type ? record.type.replaceAll('_', ' ') : ''}</Typography.Text>
        )
    },
    {
        key: 'value',
        dataIndex: 'value',
        title: 'Value'
    },
    {
        key: 'unit',
        dataIndex: 'unit',
        title: 'Unit'
    }
  
]

// let columns = [
//     {
//         key: 'baseCurrrency',
//         dataIndex: 'baseCurrency',
//         title: 'Base Currency'
//     },
//     {
//         key: 'countryCode',
//         dataIndex: 'countryCode',
//         title: 'Country Code'
//     },
//     {
//         key: 'currencyCode',
//         dataIndex: 'currencyCode',
//         title: 'Currency Code'
//     },

//     {
//         key: 'currencyCode',
//         dataIndex: 'currencyCode',
//         title: 'Currency Code'
//     },
//     {
//         key: 'title',
//         dataIndex: 'title',
//         title: 'Title'
//     },

//     {
//         key: 'type',
//         dataIndex: 'type',
//         title: 'Type'
//     },
//     {
//         key: 'value',
//         dataIndex: 'value',
//         title: 'Value'
//     },
  
// ]

const [queryName, setQueryName ] = useState('gold_bar');
const [ countryCode, setCountryCode] = useState('')
const FetchData = async () => {
    // const url = REACT_APP_BASE_URL + 'admins/rates?metalType='+ queryName
    const url = REACT_APP_BASE_URL + 'admins/rates?type='+ queryName + '&countryCode='+ countryCode
    try {
     const token = window.localStorage.getItem('s_token')
     if(!token || typeof token === 'undefined' || token === null) return
 
     const response = await axios.get(url,{
     headers: {
         'Authorization': `Bearer ${token}`
     }
     })
    //  console.log(response.data)
     if(response.status === 200 && response.data && Array.isArray(response.data.products)) {
         let br = response.data.products
 
        //  br = br.map(row => ({
        //      key: row._id.toString(),
        //      location: row.delivery && row.delivery.address ? row.delivery.address  : '',
        //      date: format(parseISO(row.createdAt), 'MM-dd-yyyy') ,
        //      time: format(parseISO(row.createdAt), 'HH:mm:ss'),
        //      ...row
        //  })
        //  );
         // dispatch
         dispatch({
             type: FETCH_RATES,
             rates: response.data
         })
 
       
         setBuyRequests(br)
         setTableData(br)
     }
 
    } catch (error) {
 
     // alert an error message
      setBuyRequests([])
      setTableData([])
    } 
}

// Fetch the data for each rates
  const queryNameFunc = (val) => {
        setQueryName(val)
    }

    useEffect(()=> {
        //initial table setting
    if(queryName === 'gold_bar'){
        setTableColumn(columns)
    }
    FetchData()
    }, [queryName, countryCode])



function renderSubMenu() {
    const subMenuStyle = {
        backgroundColor: 'white', 
        borderTopStyle: 'solid',
        borderTopColor: 'rgb(243 243 243)',
        borderTopWidth: '1px',
        padding: '1rem', 
        paddingLeft: '30px',
        paddingRight: '30px'
    }

    const btnStyle= {
        border:'none',
        boxShadow: 'none',
        color: '#94979B',
        fontSize: '13px'
        // paddingLeft: '1.5rem',
        // paddingRight: '1.5rem'
    }

    const activeStyle = {
        // backgroundColor: 'rgba(128, 0, 32, 0.05)',
        border: 'none',
        boxShadow: 'none',
        color: '#800020',
        fontWeight: 600,
        // paddingLeft: '1.5rem',
        // paddingRight: '1.5rem'
    }

    const actionBtnStyle = {
        paddingRight:'.9rem', 
        paddingLeft:'.9rem',
        paddingTop: '1.1rem',
        paddingBottom:'1.1rem', 
        backgroundColor: '#F5F5F5', 
        display: 'flex', 
        alignItems: 'center', 
        border: 'none'
    } 

   async function changeMenu(menu) {
        setActiveMenu(menu)
        queryNameFunc(menu)
        // change the table column
        let col = [
            {
                key: 'Gold medallions (oz)',
                dataIndex: 'GoldMedallions',
                title: 'Gold medallions (oz)'
            },
            {
                key: 'buyRate',
                dataIndex: 'buyRate',
                title: 'Buy rate'
            },
            {
                key: 'sellRate',
                dataIndex: 'sellRate',
                title: 'Sell Rate'
            },
        
            {
                key: 'percentageMarkup',
                dataIndex: 'percentageMarkup',
                title: 'Percentage markup'
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                  <Space size="middle">
                   
                     <Button style={{ ...actionBtnStyle, paddingLeft: '15px !important', paddingRight: '15px !important'}} shape="round" icon={<img src='icons/edit.svg' className='mr-2' />} size={10}>
                      Edit
                     </Button>
                     <Button  onClick={() => handleModalVisible(record)} style={actionBtnStyle} shape="round" icon={<CloseOutlined />} size={10}>
                      Delete
                     </Button>
                    
                  </Space>
                ),
              },     
        ]

        switch (menu) {
            
            case ratesType[0].query: 

                 setTableColumn(columns)
                break;
            case ratesType[1].query:
                // make api call set the table data on a norm 

                setTableColumn(columns)            
                break;

            case ratesType[2].query:
                // make api call set the table data on a norm 
                // col[0]= 
                columns = [
                    {
                        key: 'goldSavingsProduct',
                        dataIndex: 'goldSavingsProduct',
                        title: 'Gold savings products (grams)'
                    },
                    ...columns
                ]

                setTableColumn(columns)
                break;
            case ratesType[3].query:
                // make api call set the table data on a norm 
                columns = [
                    // {
                    //     key: 'Gold cast wire (inches)',
                    //     dataIndex: 'Gold cast wire (inches)',
                    //     title: 'Gold cast wire (inches)'
                    // },
                    ...columns,
                    {
                        key: 'forJewellers',
                        dataIndex: 'forJewellers',
                        title: 'For Jewellers', 
                        render: (text, record) => {
                           return <Typography.Text>{record && record.forJewellers ?  record.forJewellers.toString() : ''}</Typography.Text>
                        }
                    },
                ]
             

                setTableColumn(columns)
                
                break;
            case ratesType[4].query:
                // make api call set the table data on a norm 
                columns = [

                    ...columns,
                    {
                        key: 'forJewellers',
                        dataIndex: 'forJewellers',
                        title: 'For Jewellers', 
                        render: (text, record) => {
                           return <Typography.Text>{record && record.forJewellers ?  record.forJewellers.toString() : ''}</Typography.Text>
                        }
                    },
                ]
                setTableColumn(columns)
                break;
            case ratesType[5].query:
                // make api call set the table data on a norm 
                columns = [
                    // {
                    //     key: 'Gold plates & Sheets',
                    //     dataIndex: 'Gold plates & Sheets',
                    //     title: 'Gold plates & Sheets'
                    // },
                    ...columns,
                    {
                        key: 'forJewellers',
                        dataIndex: 'forJewellers',
                        title: 'For Jewellers', 
                        render: (text, record) => {
                           return <Typography.Text>{record && record.forJewellers ?  record.forJewellers.toString() : ''}</Typography.Text>
                        }
                    },
                ]

                setTableColumn(columns)
                break;
            case ratesType[6].query:
                // make api call set the table data on a norm 
                columns = [
                    {
                        key: 'Gold Alloys',
                        dataIndex: 'Gold Alloys',
                        title: 'Gold Alloys'
                    },
                    ...columns
                ]

                setTableColumn(columns)
                break;
            case ratesType[7].query:
                // make api call set the table data on a norm 
                columns = [
                    {
                        key: 'Silver bars',
                        dataIndex: 'Silver bars',
                        title: 'Silver bars'
                    },
                    ...columns
                ]

                setTableColumn(columns)
                break;
            case ratesType[8].query:
                // make api call set the table data on a norm 
                columns = [
                    {
                        key: 'Silver medallions (oz)',
                        dataIndex: 'Silver medallions (oz)',
                        title: 'Silver medallions (oz)'
                    },
                    ...columns
                ]

                setTableColumn(columns)
                break;
        
            default:
                break;
        }
        // setPageTitle(menu + ' Rates')
    }



    const rateLastChild = ratesType[ratesType.length -1]

  
    
    return (
        <>
        <Row style={subMenuStyle}>
            <Col span={24}>
                <Space direction='horizontal' size={2}>
                    {
                        ratesType.slice(0, ratesType.length - 1).map(rate => (
                            <Button key={rate.label} onClick={() =>{ changeMenu(rate.query) } } style={activeMenu === rate.query ? activeStyle : btnStyle} shape="round" size='middle'>
                            {rate.label}
                          </Button>
                        ))
                    }
                </Space>
            </Col>
        </Row>
        <Row style={subMenuStyle}>
             <Col span={24}>
                <Space direction='horizontal' size={2}>
                <Button key={rateLastChild.label} onClick={() =>{ changeMenu(rateLastChild.label) } } style={activeMenu === rateLastChild.label ? activeStyle : btnStyle} shape="round" size='middle'>
                            {rateLastChild.label}
                          </Button>
                </Space>
            </Col>
        </Row>
       </>
    )
}


//  Delete Modal
const handleModalVisible = (rate) => {
    console.log(rate)
    setRate(rate)
    setIsModalVisible(true)
}
const handleOk = () => {
    console.log(rate)
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
function renderDeleteModal() {
    // rate.title 
    return (
        <Modal title={rate &&  'Delete Sanu Gold'} visible={isModalVisible} onCancel={handleCancel} footer={null} width={400}>
        <p>What happens when you delete a rate?.</p>
        <p>The rate will no longer be available to the admin platform</p>
        <div style={{ width: '100%', display:'flex', justifyContent:'space-around' }} >
            <Button onClick={handleCancel} style={{ borderRadius: '5px' }} block type='default'>No</Button>
              <div style={{width: '20px'}} />
            <Button onClick={handleOk} style={{ borderRadius: '5px' }} block type='primary'>Yes</Button>
        </div>
      </Modal>
    )
}

    return (
        <AppLayout SubMenu={renderSubMenu()}>
            <Content>
                {/* Page Header */}
                <PageTitle title={pageTitle} />

                   {/* renderModal */}
                   {renderDeleteModal()}
                {/* Page Content */}
                <div className='bit-my bit-col' style={{flexDirection: 'column'}}>
                    {/* <SearchFilter  
                    //   pageSizeFilter={{
                    //     enabled: true,
                    //     render: <PageSizeSelect handleChange={handleChange} />
                    //   }} 
                    //   statusFilter={{
                    //       enabled: true,
                    //       render: <FilterByStatus defaultValue={statusFilter} options={statusOptions} handleChange={handleStatusChange} />
                    //   }}
                      
                    //   datePickerFilter={{
                    //       enabled: true,
                    //       render: <SelectDatePickers onStartDate={onStartDate} onEndDate={onEndDate} />
                    //   }}
                    //   searchFilter= {<Search  onSearch={onSearch} />}
                     
                    /> */}
                {/* <div style={{padding: '2rem 1rem'}}>
                    <CurrencySelect size={150} handleChange={(data) => onHandleCountrySelect(data)} />
                </div> */}
                     
                <Row >
                    <Col style={{width: '100%'}}>
                            <BitTable 
                            columns={tableColumn} 
                            dataSource={tableData} 
                            pagination={{ 
                                total: tableData.length,
                                // showSizeChanger: true,
                                // showQuickJumper: true,
                                showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
                                defaultPageSize: pageSize,
                                pageSize,
                                defaultCurrent: 1
                              }} 
                              />
                        
                    </Col>
                </Row>
              </div>
            </Content>
        </AppLayout>
    )
}

export default Rates

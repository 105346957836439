import React, {useState, useEffect} from 'react'
import {Button, Card, Col, Image, Layout, Row, Space, Typography} from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import BitTable from '../Components/BitTable'
import  AppLayout from '../Components/Layouts'
import PageTitle from '../Components/PageTitle'
import RenderTotal from '../Components/RenderTotal'
import Chart, { CurrencySelect, DaySelect, MiniChart, WeightSelect } from '../Components/Chart'

import { useRequestContext } from '../Contexts/RequestContext'

// svgs
import LineChart from '../Components/Chart/chart-'
import { Bitcoin, CaretDown, CaretUp, ChartOne, ChartTwo, ChartThree, Copy, Ethereum, User } from '../icons'
import { FETCH_BUY_REQUEST, FETCH_SELL_REQUEST } from '../Contexts/type'


const {REACT_APP_BASE_URL} = process.env 

function Dashboard() {
    const {Content} = Layout

    const {dispatch} = useRequestContext()

    const [isChartRendered, setIsChartRendered] = useState(false)
    const [buyRequests, setBuyRequests] = useState([])
    const [sellRequests, setSellRequests] = useState([])
    const [recentTransations, setRecentTransactions] = useState([])
    const [ totalTrade , setTotalTrade ] = useState()
    const [ totalTransactions , setTotalTransactions ] = useState()
    const [ users, setUsers] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsChartRendered(true)
    }, [] )


    async function getBuyRequest() {
        const url = REACT_APP_BASE_URL + 'admins/vending/buy_requests'
       try {
        const token = window.localStorage.getItem('s_token')
        if(!token || typeof token === 'undefined' || token === null) return

        const response = await axios.get(url,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
        })

        if(response.status === 200 && response.data && Array.isArray(response.data.buyRequests)) {
            let br = response.data.buyRequests.slice(0, 4)
            br = br.map(row => ({
                key: row._id.toString(),
                name: row.userId ? row.userId.firstName + ' ' + row.userId.lastName : '',
                ...row
            })
            );
            // dispatch
            dispatch({
                type: FETCH_BUY_REQUEST,
                buyRequests: response.data
            })

          
            setBuyRequests(br)
        }

       } catch (error) {

        // alert an error message
         setBuyRequests([])
           
       } 
    }

    async function getSellRequest() {
        const url = REACT_APP_BASE_URL + 'admins/vending/sell_requests'
       try {
        const token = window.localStorage.getItem('s_token')
        if(!token || typeof token === 'undefined' || token === null) return

        const response = await axios.get(url,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
        })

        if(response.status === 200 && response.data && Array.isArray(response.data.sellRequests)) {
            let sr = response.data.sellRequests.slice(0, 4)

            sr = sr.map(row => ({
                key: row._id.toString(),
                name: row.userId.firstName + ' ' + row.userId.lastName,
                amount: row.carat,
                ...row
            })
            );
            // dispatch
            dispatch({
                type: FETCH_SELL_REQUEST,
                sellRequests: response.data
            })

          
            setSellRequests(sr)
        }

       } catch (error) {

        // alert an error message
         setSellRequests([])
           
       } 
    }

    async function getRecentTransactions() {
        const url = REACT_APP_BASE_URL + 'admins/transactions'
        try {
         const token = window.localStorage.getItem('s_token')
         if(!token || typeof token === 'undefined' || token === null) return
 
         const headers = {'Authorization': `Bearer ${token}`}

         const response = await axios.get(url,{
         headers 
         })
 
         if(response.status === 200 && response.data && Array.isArray(response.data.transactions)) {
             setTotalTransactions(response.data.transactions.length)
             let transactions = response.data.transactions.slice(0, 5)
 
             transactions = await Promise.all(transactions.map(async(transaction) => {
               const res = await axios.get(`${REACT_APP_BASE_URL}admins/users/${transaction.userId}`, {
                     headers
                 })
                 
                 if(res.status === 200 && res.data && res.data.user) {
                     return {
                         ...transaction,
                         userId: {...res.data.user}
                     }
                 }

                 return transaction
             }
             )
             );
             // dispatch
            //  dispatch({
            //      type: FETCH_SELL_REQUEST,
            //      sellRequests: response.data
            //  })
 
            
             setRecentTransactions(transactions)
         }
 
        } catch (error) {
 
         // alert an error message
          setRecentTransactions([])
            
        } 
    }

    async function getTotalAnalytics() {
        const url = REACT_APP_BASE_URL + 'admins/analytics'
      
       try {
        const token = window.localStorage.getItem('s_token')
        if(!token || typeof token === 'undefined' || token === null) return

        const response = await axios.get(url,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
        })
  
        if(response.status === 200 && response.data) {
            setTotalTrade(response.data.totalTrade)
            
        }

       } catch (error) {

       } 

       
    }

    const getUsersTotal = async  () => {
        const url2 = REACT_APP_BASE_URL + 'admins/users'
        try {
            const token = window.localStorage.getItem('s_token')
            if(!token || typeof token === 'undefined' || token === null) return
    
            const response = await axios.get(url2, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
            })
      
            if(response.status === 200 && response.data) {
                // alert(JSON.stringify(response.data.users))
                setUsers(response.data.users.length)
                
            }
    
           } catch (error) {
    console.log('new',error)
           } 
    }

   useEffect(() => {
      getBuyRequest()
      getSellRequest()
      getRecentTransactions().then(()=> {
    
      })
      getTotalAnalytics()
      getUsersTotal()
   }, [])



//    Request Methods
function approveBuyRequest(record) {
  
    setIsLoading({
        id: record._id,
        type: 'approve' 
    })
}


function rejectBuyRequest(record) {
    console.log(record);
}

function approveSellRequest(record) {
    console.log(record)
}

function rejectSellRequest(record) {
    console.log(record)
}


// First Chart
    function renderFirstChart(params) {

        const subtitle = (
            <Space direction='vertical' size={.05}>
                <Typography.Text  style={{fontFamily:'Rubik', fontWeight: '500'}}>NGN658,051.79</Typography.Text>
                <Space direction='horizontal' size={5}>
                    <Image src={CaretUp}  />
                  <Typography.Text style={{color: '#73AF00'}}>126.85 0.02%</Typography.Text>
                </Space>

            </Space>
        )

        return (
            <Card title='Sanu Gold Price' extra={subtitle} style={{ width: '100%', fontFamily:'Rubik', borderRadius: '5px' }}> 
            {isChartRendered && <div style={{ maxWidth: '300px'}}> <Chart /> </div>}
            <Row justify='space-between' align='middle'>
                <Col span={8}>
                    <CurrencySelect />
                </Col>
                <Col span={8}>
                    <WeightSelect />
                </Col>
                <Col span={8}>
                    <DaySelect />
                </Col>
            </Row>
        </Card>
        )
    }

    // Second Chart
    function renderSecondChart(params) {

        const subtitle = (
            <Space direction='vertical' size={.05}>
                <Typography.Text  style={{fontFamily:'Rubik', fontWeight: '500'}}>NGN9228.73</Typography.Text>
                <Space direction='horizontal' size={5}>
                    <Image src={CaretDown}  />
                  <Typography.Text style={{color: '#D80027'}}>199.26 -2.11%</Typography.Text>
                </Space>

            </Space>
        )

        return (
            <Card title='Sanu Silver Price' extra={subtitle} style={{ width: '100%', height: '100%', fontFamily:'Rubik', borderRadius: '5px' }}> 
             {isChartRendered && <div style={{ maxWidth: '300px'}}> <Chart /> </div>}
            <Row justify='space-between' align='middle'>
                <Col span={8}>
                    <CurrencySelect />
                </Col>
                <Col span={8}>
                    <WeightSelect />
                </Col>
                <Col span={8}>
                    <DaySelect />
                </Col>
            </Row>
        </Card>
        )
    }
 


    // Mini Charts
    function renderFirstMiniChart(params) {

        const title = (
            <Space direction='horizontal' size='small' style={{alignItems: 'center', display: 'flex'}}>
                <Image preview={false} src={Bitcoin} width={20} /> <span>Bitcoin</span>
            </Space>
        )

        return (
            <Card title={title} extra={<CurrencySelect />} style={{ width: '100%', height: '171.5px', fontFamily:'Rubik', borderRadius: '5px' }}> 
              <Space direction='vertical' size='middle'>

                 {isChartRendered && <MiniChart />}
           
             <Space direction='horizontal' size='large'>
                <Typography.Text  style={{fontFamily:'Rubik', fontWeight: '500'}}>NGN9228.73</Typography.Text> 
                <Typography.Text style={{color: '#73AF00'}}>+3.31%</Typography.Text>
             </Space>
            </Space>
        </Card>
        )
    }

    function renderSecondMiniChart(params) {


        const title = (
            <Space direction='horizontal' size='small' style={{alignItems: 'center', display: 'flex'}}>
                <Image preview={false} src={Ethereum} width={20} /> <span>Ethereum</span>
            </Space>
        )

        return (
        <Card title={title} extra={<CurrencySelect />} style={{ width: '100%', height: '174px', fontFamily:'Rubik', marginTop:'1rem', borderRadius: '5px' }}> 
             <Space direction='vertical' size='middle'>
                   {isChartRendered && <MiniChart />}
        
             <Space direction='horizontal' size='large'>
                <Typography.Text  style={{fontFamily:'Rubik', fontWeight: '500'}}>NGN9228.73</Typography.Text> 
                <Typography.Text style={{color: '#73AF00'}}>+3.31%</Typography.Text>
                 </Space>
            </Space>
        </Card>
        )
    }

    

    // Gold SilverRequest
    function renderGoldSilverBuyRequest() {
        
      const btnStyle = {paddingRight:'.3rem', paddingLeft:'.3rem', backgroundColor: '#F5F5F5', display: 'flex', alignItems: 'center'} 
      const columns = [
          {
              key: 'name',
              dataIndex: 'name',
              title: 'Name'
          },
          {
              key: 'type',
              dataIndex: 'type',
              title: 'Type'
          },
          {
              key: 'amount',
              dataIndex: 'amount',
              title: 'Amount',
              render: (text, record) => (
                  <Typography.Text>{parseFloat(record.amount).toFixed(2).toString()}</Typography.Text>
              )
          },
          {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                 <Button loading={isLoading && isLoading.id === record._id && isLoading.type === 'approve'} onClick={() => approveBuyRequest(record)} style={btnStyle} shape="round" icon={<CheckOutlined  />} size={10}>
                  Approve
                 </Button>
                 <Button loading={isLoading && isLoading.id === record._id && isLoading.type === 'reject'}  onClick={() => rejectBuyRequest(record)}  style={btnStyle} shape="round" icon={<CloseOutlined />} size={10}>
                  Reject
                 </Button>
                
              </Space>
            ),
          },
      ]
        
        return (
            <Card className='bit-request-card' title='Gold/Silver Buy Requests' extra={<Link to='/buy'>View all</Link>} style={{borderRadius: '5px', height:'100%'}}>
                <BitTable dataSource={buyRequests} columns={columns}   />
            </Card>
        )
    }
 
    // Gold Silver SellRequest 
    function renderGoldSilverSellRequest() {
        
      const btnStyle = {paddingRight:'.3rem', paddingLeft:'.3rem', backgroundColor: '#F5F5F5', display: 'flex', alignItems: 'center'} 
      const columns = [
          {
              key: 'name',
              dataIndex: 'name',
              title: 'Name'
          },
          {
              key: 'type',
              dataIndex: 'type',
              title: 'Type'
          },
          {
              key: 'amount',
              dataIndex: 'amount',
              title: 'Amount'
          },
          {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                 <Button loading={isLoading && isLoading.id === record._id && isLoading.type === 'approve'}  style={btnStyle} shape="round" icon={<CheckOutlined  />} size={10}>
                  Approve
                 </Button>
                 <Button  style={btnStyle} shape="round" icon={<CloseOutlined />} size={10}>
                  Reject
                 </Button>
                
              </Space>
            ),
          },
      ]
        
        return (
            <Card className='bit-request-card' title='Gold/Silver Sell Requests' extra={<Link to='/sell'>View all</Link>} style={{borderRadius: '5px', height:'100%'}}>
                <BitTable dataSource={sellRequests} columns={columns}   />
            </Card>
        )
    }


    // Render Transaction Cards
    function renderTransactions() {
        const trxStyle = {fontFamily: 'Rubik'}
        const columns = [{

                title: '',
                key: 'walletId',
                render: (text, record) => {
                   
                    if(record.transactionType === 'Funding') {
                        return (
                            <Typography.Text style={trxStyle}>
                                {record.description}  <b>{record.userId && record.userId.firstName + ' ' + record.userId.lastName }</b>
                            </Typography.Text>
                        )
                    }

                    if(record.transactionType === 'Gold Buy' && record.description.includes('wallet')) {
                        return (
                            <Typography.Text style={trxStyle}>
                                {record.description}  <b>{record.userId && record.userId.firstName + ' ' + record.userId.lastName }</b>
                            </Typography.Text>
                        )
                    }

                    if(record.transactionType === 'Gold Sell' && record.description.includes('wallet')) {
                        return (
                            <Typography.Text style={trxStyle}>
                             {record.description}  <b>{record.userId && record.userId.firstName + ' ' + record.userId.lastName }</b>
                            </Typography.Text>
                        )
                    }
                },
        }]
        return (
            <Card 
            className='bit-request-card' 
            title='Latest Transactions' 
            extra={<Link to='/transactions'>View all</Link>} 
            style={{borderRadius: '5px', height:'100%'}}
            >
            <BitTable dataSource={recentTransations} columns={columns}   />
        </Card>
        )
    }



    return (
        <AppLayout>
                <Content>
                    {/* Header */}
                    <PageTitle title='Dashboard' />

                    {/* Total */}
                    <Row className='bit-grid'>
                        <RenderTotal title='Total Users' total={users} icon={<Image preview={false} src={User} width={20} />}/>
                        <RenderTotal title='Total Trades' total={totalTrade} icon={<Image preview={false} src={ChartOne} width={20} />}/>
                        <RenderTotal title='Value of Trade' total='' icon={<Image preview={false} src={ChartTwo} width={20} />}/>
                        <RenderTotal title='Total Transactions' total={totalTransactions} icon={<Image preview={false} src={ChartThree} width={20} />}/>
                        <RenderTotal title='Total Orders' total='' icon={<Image preview={false} src={Copy} width={20} />}/>
                    </Row>

                    {/* Charts */}
                    <Row className='bit-my' gutter={[16, 16]}>
                        {/* First Chart */}
                        <Col sm={24} lg={8} style={{paddingLeft: 0}}>
                           {renderFirstChart()}
                        </Col>
                        
                        {/* Second Chart */}
                        <Col sm={24} lg={8}>
                            {renderSecondChart()}
                        </Col>

                        {/* Mini Charts */}
                        <Col sm={24} lg={8} style={{ paddingRight: 0}}>
                            {renderFirstMiniChart()}
                            {renderSecondMiniChart()}
                        </Col>
                    </Row>
                    {/* End Charts */}


                    {/* Request Tables */}
                    <Row className='bit-my' gutter={[16, 16]}>
                        <Col sm={24} lg={12} style={{ paddingLeft: 0 }}>
                            {renderGoldSilverBuyRequest()}
                        </Col>
                      
                        <Col sm={24} lg={12} style={{paddingRight: 0}}>
                            {renderGoldSilverSellRequest()}
                        </Col>
                    </Row>

                    {/* Render Transactions */}
                    <Row>
                        <Col style={{width: '100%'}} className='bit-transaction'>
                            {renderTransactions()}
                        </Col>
                    </Row>

                </Content>
        </AppLayout>
    )
}

export default Dashboard

import React from 'react';
import Table from './Table'



function Footer() {

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '3% 3%',
            marginTop: '4%',
            width: '90%',


        }}>
            <div className="a-dropdown" style={{
                marginRight: '25%'
            }}>
                <select name="dropdown" id="dropdown" className="a-dropdown__select">
                    <option value="Value 1">Sanu silver</option>
                    <option value="Value 1">Sanu gold</option>
                    <option value="Value 1">Nigerian NGN</option>
                    <option value="Value 1">Rwandan Franc</option>
                    <option value="Value 1">S. A Rand</option>
                    <option value="Value 1">B.faso CFA</option>
                    <option value="Value 1">G, Bissau CFA</option>
                    <option value="Value 1">Mali CFA</option>
                    <option value="Value 1">Benin CFA</option>
                    <option value="Value 1">C. d’ivoire CFA</option>
                    <option value="Value 1">Senegal CFA</option>
                    <option value="Value 1">Togo CFA</option>

                    <option value="Value 1">Niger CFA</option>
                    <option value="Value 1">Bitcoin</option>
                    <option value="Value 1">Ethereum</option>
                    <option value="Value 1"> USDT</option>

                </select>
                <svg viewBox="0 0 12 12" className="a-dropdown__arrow icon icon-chevron">
                    <path d="M4.658 0.439c-0.585-0.585-1.534-0.585-2.119 0s-0.585 1.534 0 2.119l3.443 3.443-3.443 3.442c-0.585 0.585-0.585 1.534 0 2.119s1.534 0.585 2.119 0l4.49-4.489c0.004-0.004 0.008-0.008 0.012-0.012 0.028-0.028 0.054-0.056 0.079-0.085 0.506-0.589 0.48-1.477-0.078-2.034-0.012-0.012-0.025-0.024-0.037-0.036l-4.466-4.466z" />
                </svg>
            </div>
            <div className="a-dropdown">
                <select name="dropdown" id="dropdown" className="a-dropdown__select">
                    <option value="Value 1">oz</option>
                    <option value="Value 1">g</option>
                    <option value="Value 1">kg</option>
                </select>
                <svg viewBox="0 0 12 12" className="a-dropdown__arrow icon icon-chevron">
                    <path d="M4.658 0.439c-0.585-0.585-1.534-0.585-2.119 0s-0.585 1.534 0 2.119l3.443 3.443-3.443 3.442c-0.585 0.585-0.585 1.534 0 2.119s1.534 0.585 2.119 0l4.49-4.489c0.004-0.004 0.008-0.008 0.012-0.012 0.028-0.028 0.054-0.056 0.079-0.085 0.506-0.589 0.48-1.477-0.078-2.034-0.012-0.012-0.025-0.024-0.037-0.036l-4.466-4.466z" />
                </svg>
            </div>
            <div className="a-dropdown">
                <select name="dropdown" id="dropdown" className="a-dropdown__select">
                    <option value="Value 1">1 days</option>
                    <option value="Value 1">30 days</option>
                    <option value="Value 1">60 days</option>
                </select>
                <svg viewBox="0 0 12 12" className="a-dropdown__arrow icon icon-chevron">
                    <path d="M4.658 0.439c-0.585-0.585-1.534-0.585-2.119 0s-0.585 1.534 0 2.119l3.443 3.443-3.443 3.442c-0.585 0.585-0.585 1.534 0 2.119s1.534 0.585 2.119 0l4.49-4.489c0.004-0.004 0.008-0.008 0.012-0.012 0.028-0.028 0.054-0.056 0.079-0.085 0.506-0.589 0.48-1.477-0.078-2.034-0.012-0.012-0.025-0.024-0.037-0.036l-4.466-4.466z" />
                </svg>
            </div>

        </div >
    );
}

export default Footer;

import React, { Component } from 'react';
// import './App.css';
import AppRouter from './router'
import { GlobalProvider } from './Contexts/GlobalContext';
import { RequestProvider } from './Contexts/RequestContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/global.less'

class App extends Component {
 

  render() {
    return (
      <GlobalProvider>
        <RequestProvider>
          <AppRouter />
        </RequestProvider>
      </GlobalProvider>
    );
  }
}

export default App;

// const AppRender = ()=> (
//   <React.Fragment>   
//     <DashboardLayout>
//       <Switch>
//          <Route path="/" render={(props) => { return ( <HomePage {...props} />)}}/>
//       </Switch>
//       </DashboardLayout> 
//   </React.Fragment>
// )

// function App() {
//   return (
//     // <ChakraProvider resetCSS theme={theme}>
//     <Router> 
//       <AppRender/>
//     </Router>
//     // </ChakraProvider>
//   )
// }

// export default App;
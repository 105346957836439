import React from 'react';
import Divider from '@material-ui/core/Divider';



function TableS(props) {

    let b = props.body ? props.body.slice(0, 3) : ''


    return (
        <div>
            <div style={{
                marginTop: '0%', background: '#FFFFFF',
                boxShadow: ' 0px 0px 2px rgba(0, 0, 0, 0.1)',
                borderRadius: '4px',
                width: '100%',

            }} className='desktop'>


                <div style={{
                    background: '#F4F4F4',
                }}>

                    {props.header ? props.header.map((i) => {
                        return (
                            <div style={{
                                marginTop: '-15px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '90%',
                                margin: '0 2%',
                            }}>
                                {i.name ? <p className='tabP'>{i.name}</p> : ''}
                                {i.value ? <p className='tabP'>{i.value}</p> : ''}
                                {i.carat ? <p className='tabP'>{i.carat}</p> : ''}
                                {i.weight ? <p className='tabP'>{i.weight}</p> : ''}
                                {i.layaway ? <p className='tabP'>{i.layaway}</p> : ''}
                                {i.matDate ? <p className='tabP'>{i.matDate}</p> : ''}
                                {i.type ? <p className='tabP'>{i.type}</p> : ''}

                                {i.amount ? <p className='tabP'>{i.amount}</p> : ''}
                                {i.identityT ? <p className='tabP'>{i.identityT}</p> : ''}
                                {i.dateV ? <p className='tabP'>{i.dateV}</p> : ''}
                                {i.status ? <p className='tabP'>{i.status}</p> : ''}
                                {i.action ? <p className='tabP'>{i.action}</p> : ''}

                                {i.location ? <p className='tabP'>{i.location}</p> : ''}
                                {i.createdAt ? <p className='tabP'>{i.createdAt}</p> : ''}


                            </div>
                        )
                    })
                        : 'no data'}
                </div>
                <div>
                    {b.length >= 1 ? b.map((i) => {
                        return (
                            <div>
                                <div style={{
                                    marginTop: '-15px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '90%',
                                    margin: '0 2%',
                                }}>





                                    {i.type ? <p className='tabPB'>{i.type}</p> : ''}

                                    {i.userId && i.userId.firstName ? <p className='tabPB' style={{ width: '25%' }}>{i.userId.firstName + ' '}{i.userId.lastName}</p> : <p style={{ display: 'none' }}></p>}
                                    {i.value ? <p className='tabPB'>{i.value === 0 ? '0' : i.value}</p> : ''}
                                    {i.carat ? <p className='tabPB'>{i.carat}</p> : ''}
                                    {i.weight ? <p className='tabPB'>{i.weight}</p> : ''}
                                    {/* {i.layaway ? <p className='tabPB'>{i.layaway}</p> : ''} */}



                                    {i.status ? <p className='tabPB'>{i.status}</p> : ''}



                                    {i.delivery ? <p className='tabPB'>{i.delivery.type}</p> : ''}
                                    {i.amount ? <p className='tabPB'>{i.amount.toString().substr(0, 5) + '...'}</p> : ''}




                                    {i.delivery ? <p className='tabPB'>{i.delivery.address}</p> : ''}

                                    {i.createdAt ? <p className='tabPB'>{i.createdAt}</p> : ''}


                                    {i.description ? <p className='tabPB'>{i.description}</p> : ''}



                                </div>
                                <Divider />
                            </div>


                        )
                    })
                        :
                        <div className='noData' style={{
                            height: '14.5vh'
                        }}>
                            no data
                        </div>
                    }
                </div>




            </div >


            <div className='mobile'>
                {props.body ? props.body.map((i) => {
                    return (
                        <div style={{
                            display: 'flex', background: '#FFFFFF',
                            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
                            borderRadius: '4px',
                            margin: '2% 0',
                            width: '93%',
                        }}>
                            <div style={{
                                marginTop: '-15px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '90%',
                                margin: '0 2%',
                                flexDirection: 'column',
                                background: '#F4F4F4',
                                borderRadius: '4px',
                            }}>
                                {props.header[0].name ? <p className='tabP'>{props.header[0].name}</p> : ''}
                                {props.header[0].type ? <p className='tabP'>{props.header[0].type}</p> : ''}
                                {props.header[0].date ? <p className='tabP'>{props.header[0].date}</p> : ''}
                                {props.header[0].time ? <p className='tabP'>{props.header[0].time}</p> : ''}
                                {props.header[0].amount ? <p className='tabP'>{props.header[0].amount}</p> : ''}
                                {props.header[0].identityT ? <p className='tabP'>{props.header[0].identityT}</p> : ''}
                                {props.header[0].dateV ? <p className='tabP'>{props.header[0].dateV}</p> : ''}
                                {props.header[0].status ? <p className='tabP'>{props.header[0].status}</p> : ''}
                                {props.header[0].action ? <p className='tabP'>{props.header[0].action}</p> : ''}

                            </div>
                            <div style={{
                                marginTop: '-15px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '90%',
                                margin: '0 2%',
                                background: 'white',
                                flexDirection: 'column',

                            }}>


                                {i.name ? <div> <p className='tabPB'>{i.name}</p> < Divider /> </div> : ''}
                                {i.data ? <div> <p className='tabPB'>{i.data}</p>< Divider /> </div> : ''}
                                {i.type ? <div> <p className='tabPB'>{i.type}</p>< Divider /> </div> : ''}
                                {i.time ? <div> <p className='tabPB'>{i.type}</p>< Divider /> </div> : ''}
                                {i.amount ? <div><p className='tabPB'>{i.amount}</p> < Divider /> </div> : ''}
                                {i.identityT ? <div><p className='tabP'>{i.identityT}</p> < Divider /> </div> : ''}








                            </div>
                        </div>


                    )
                }) : ''
                }
            </div>


        </div>
    );
}

export default TableS;

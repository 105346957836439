import React, {useState} from 'react'
import {Breadcrumb, Button, Col, Form, Image, Input, Layout,Modal, notification, Row, Typography, Upload} from 'antd'
import axios from 'axios'
import {CloudUploadOutlined} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import AppLayout from '../Components/Layouts'
import PageTitle from '../Components/PageTitle'
import {Upload as UploadIcon} from '../icons'


const {REACT_APP_BASE_URL} = process.env

function AddWallet() {
    const {Content} = Layout
    const [form] = Form.useForm()
    const [files, setFiles] = useState([])
    const [previewImage, setPreviewImage] = useState('')
    // const [previewVisible, setPreviewVisible] = useState(false)

    const buttonStyle = { borderRadius: '8px', color: 'white', marginTop: '2rem',  width: '100%'}
    const inputStyle = { borderRadius: '8px'}
    const labelStyle = { marginBottom: 0}


    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }


    const handleFileUpload = ({file}) => {
        
        if(file && file.status !== 'error'){
            setFiles([file])
            handlePreview(file)
        }
    }
    // const handleCancel = () => setPreviewVisible(false)

    async function handlePreview(file) {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview)
        // setPreviewVisible(true)
        // previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    };


    // HandleForm Submission
    async function handleOnSubmit(values) {
       
        if(files.length === 1 && values.title.length > 0) {
            const formData = new  FormData();
            console.log(files[0].originFileObj)
            formData.append('flag', files[0].originFileObj)
            formData.append('title', values.title)
            formData.append('countryCode', values.countryCode)
            formData.append('countryCode', values.countryCode)
            try {
               const response =  await  axios.post(`${REACT_APP_BASE_URL}/admins/wallets`, 
                   formData
            ,
                {
         
                    headers: {
                      Authorization : `Bearer ${localStorage.getItem('s_token')}`,
                      'Content-Type' :'multipart/form-data'
                    }
                      
                   }
                
                )
                notification.success({
                    message: 'New wallet Added'
                })
                if(response.status === 200){
                    notification.success({
                        message: 'New wallet Added'
                    })
                }
                form.resetFields()
            } catch (error) {
                notification.error({
                    message: error.response.data.message
                })
            }
        } else {
            notification.error({
                message: 'Upload file'
            })
        }
    }
  
  
    function renderForm() {

        // api takes title, countryCode, currencyCode
        return (
            <Form
            form={form}
            layout='vertical'
            size='large'
            initialValues={{

            }}
            onFinish={handleOnSubmit}
            >
                 <Form.Item name='title' label="Wallet name" className='bit-form-label' style={labelStyle} >
                    <Input placeholder="Enter wallet name" style={inputStyle} />
                </Form.Item>
                 <Form.Item name='currencyCode' label="Currency" className='bit-form-label' style={labelStyle} >
                    <Input placeholder="Enter currency" style={inputStyle} />
                </Form.Item>
                 <Form.Item name='countryCode' label="Country code" className='bit-form-label' style={labelStyle} >
                    <Input placeholder="Enter country code" style={inputStyle} />
                </Form.Item>

                <Button type='primary' htmlType='submit' style={buttonStyle}>Save</Button>
            </Form>
        )
    }


    function renderFileUpload() {
        
        return (
            <div className='bit-upload' style={{
                marginLeft: '5rem',
                width: '200px',
                height: '200px'
            }}>
            <Upload 
            //  accept="image/jpeg"
             action=''
             fileList={files}
            //  listType='picture-card'
             onPreview={handlePreview}
             onChange={handleFileUpload}
             onRemove={(file) => console.log(file)}
            >
                <div style={{
                    // backgroundColor: ' #F4F4F4',
                    borderRadius: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Image src={previewImage ? previewImage : UploadIcon} preview={false} />
                    <Button type='link'
                    style={{ color: '#800020', textAlign:"center", marginTop: '1rem' }}>
                        Upload Currency Image 
                    </Button>
                </div>
            </Upload>
            
            {/* <Modal
            visible={previewVisible}
            // title={previewTitle}
            footer={null}
            onCancel={handleCancel}
            >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal> */}
            </div>
        )
    }

    return (
        <AppLayout>
            <Content style={{height: '90%'}}>
                {/* Page Header */}
                <PageTitle title='Wallets' />
                <Breadcrumb separator='>'>
                     <Breadcrumb.Item>
                        <Link to='/wallets' style={{color: '#800020',  }}>Wallets</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                         <Typography.Text style={{ color: '#828282' }}>Add wallet</Typography.Text>   
                     </Breadcrumb.Item>
                </Breadcrumb>
                
                  {/* Page Content */}
                  <div className='bit-my  bit-col' style={{flexDirection: 'column', maxHeight: '713px', height: '100%'}}>
                      <Row  style={{ padding: '1.5rem', paddingLeft: '3rem'}}>
                          <Col sm={24} lg={8}>
                              {/* RenderForm */}
                              {renderForm()}
                          </Col>
                          <Col sm={24} lg={12}>
                              {/* RenderUpload */}
                              {renderFileUpload()}
                          </Col>
                      </Row>
                  </div>
            </Content>
        </AppLayout>
    )
}

export default AddWallet

import React, { Component } from 'react';
import { VictoryLine } from 'victory'
import { VictoryAxis } from 'victory'
import { VictoryBrushContainer } from 'victory'
import { VictoryChart } from 'victory'
import { VictoryZoomContainer } from 'victory'
import Footer from '../activityFooter'


class LineChart extends React.Component {

	constructor() {
	  super();
	  this.state = {};
	}
  
	handleZoom(domain) {
	  this.setState({selectedDomain: domain});
	}
  
	handleBrush(domain) {
	  this.setState({zoomDomain: domain});
	}
  
	render() {
	  return (
		<>
			<VictoryChart
			  width={350}
			//   height={350}
			  scale={{x: "time"}}
			  containerComponent={
				<VictoryZoomContainer responsive={false}
				  zoomDimension="x"
				  zoomDomain={this.state.zoomDomain}
				  onZoomDomainChange={this.handleZoom.bind(this)}
				/>
			  }
			>
			  <VictoryLine
				style={{
				  data: {stroke: "#800020", strokeWidth: 1}
				}}
				data={[
				  {x: new Date(1982, 1, 1), y: 125},
				  {x: new Date(1987, 1, 1), y: 257},
				  {x: new Date(1993, 1, 1), y: 345},
				  {x: new Date(1997, 1, 1), y: 515},
				  {x: new Date(2001, 1, 1), y: 132},
				  {x: new Date(2005, 1, 1), y: 305},
				  {x: new Date(2020, 1, 1), y: 40},
				  {x: new Date(2020, 1, 1), y: 20},
				  {x: new Date(2020, 1, 1), y: 10},
				  {x: new Date(2020, 1, 1), y: 37},
				  {x: new Date(2020, 1, 1), y: 75},
				  {x: new Date(2020, 1, 1), y: 10},
				  {x: new Date(2020, 1, 1), y: 120},
				  {x: new Date(2020, 1, 1), y: 25},
				  {x: new Date(2020, 1, 1), y: 33},
				  {x: new Date(2020, 1, 1), y: 150},
				  {x: new Date(2021, 1, 1), y: 18},
				  {x: new Date(2021, 1, 1), y: 14},
				  {x: new Date(2021, 1, 1), y: 26},
				  {x: new Date(2021, 1, 1), y: 12},
				  {x: new Date(2021, 1, 1), y: 34},
				  {x: new Date(2021, 1, 1), y: 50},
				  {x: new Date(2021, 1, 1), y: 13},
				  {x: new Date(2021, 1, 1), y: 40},
				  {x: new Date(2021, 1, 1), y: 50},
				  {x: new Date(2021, 1, 1), y: 12},
				  {x: new Date(2021, 1, 1), y: 10},
				  {x: new Date(2021, 1, 1), y: 30},
				
				]}
			  />
  
			</VictoryChart>
  <Footer/>
		</>
	  );
	}
  }

export default LineChart;                           
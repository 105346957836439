/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect, useState} from 'react'
import {Avatar, Breadcrumb, Button, Col, Image, Layout, Modal, Row, Space, Typography} from 'antd'
import {UpOutlined,UserOutlined} from '@ant-design/icons'
import axios from 'axios'
import {Link, useParams} from 'react-router-dom'
import AppLayout from '../Components/Layouts'
import BitTable from '../Components/BitTable'
import PageTitle from '../Components/PageTitle'

const {REACT_APP_BASE_URL} = process.env

function UserDetails() {
    const {Content} = Layout
    
    const params = useParams()
   const [user, setUser] = useState(undefined)
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [userWallets, setUserWallets] = useState([])
   let [idTableData, setIdTableData] = useState([])
   const [imagePreview, setImagePreview] = useState(false)

    async function getUser() {
        const url = REACT_APP_BASE_URL + `admins/users/${params.id}`
        try {
            const token = window.localStorage.getItem('s_token')
            if(!token || typeof token === 'undefined' || token === null) return
        
            const response = await axios.get(url,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
            })
            if(response.status === 200 && response.data) {
                console.log(response.data.user)
                setUser(response.data.user)
            }
        } catch (error) {
            console.log(error)
            setUser({})
        }
    }


  function fullname(user) {
    return user && user.firstName && user.lastName  && user.firstName + ' ' + user.lastName
  }
  

    async function getUserWallets() {
        const url = REACT_APP_BASE_URL + `admins/user_wallets?userId=${params.id}`
        try {
            const token = window.localStorage.getItem('s_token')
            if(!token || typeof token === 'undefined' || token === null) return
        
            const response = await axios.get(url,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
            })
            if(response.status === 200 && response.data) {
                console.log(response.data)
                const wallets = Array.isArray(response.data.userWallets) && response.data.userWallets.map(w => {
                    if(typeof w.balance === 'number') {
                        return w;
                    } else {
                      return  {
                            ...w,
                            balance: w.balance['$numberDecimal']
                        }
                    }
                })
                setUserWallets(wallets)
            }
        } catch (error) {
            console.log(error)
            setUserWallets([])
        }
    }

    useEffect(() => {
       getUser()
       getUserWallets()
    }, [params])

// personal info
function renderPersonalInfo() {

    
   const spacerStyle = {
       borderBottomColor: '#f1f1f1',
       borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        paddingBottom: '.5rem',
        paddingTop: '.5rem',
        width: '70%'
    }

    


    return (
        <Col span={24}>
            <Row>
                <Col lg={12} > 
                  <Typography.Title level={5}>Personal Info</Typography.Title>
                  <Space direction='horizontal' size={60} style={spacerStyle}>
                    <Typography.Text>Name:</Typography.Text>
                    <Typography.Text style={{textTransform: 'capitalize'}} >{user && user.firstName && user.lastName  && user.firstName + ' ' + user.lastName }</Typography.Text>
                  </Space>
                  <Space direction='horizontal' size={35} style={spacerStyle}>
                    <Typography.Text>Phone no:</Typography.Text>
                    <Typography.Text>{user && user.phone && user.phone }</Typography.Text>
                  </Space>
                  <Space direction='horizontal' size={9} style={spacerStyle}>
                    <Typography.Text>Email address:</Typography.Text>
                    <Typography.Text>{user && user.email && user.email }</Typography.Text>
                  </Space>
                  <Space direction='horizontal' size={42} style={spacerStyle}>
                    <Typography.Text>SANU ID:</Typography.Text>
                    <Typography.Text>{user && user.sanuId && user.sanuId }</Typography.Text>
                  </Space>
                </Col>
                <Col lg={6}> 
                    <Avatar size={150} icon={<UserOutlined />} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} />
                </Col>
                <Col lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}> 
                    <Button 
                     onClick={() => handleModalVisible(user)}
                     style={{
                        alignSelf:'flex-end',
                        borderColor: '#8f1832',
                        borderRadius: '5px',
                        color: '#8f1832',
                    }}>
                        Deactivate User
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

//  Delete Modal
const handleModalVisible = (User) => {
    console.log(User)
    setUser(User)
    setIsModalVisible(true)
}
const handleOk = () => {
    console.log(user)
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
function renderDeleteModal() {
    // rate.title 
    return (
        <Modal title={'Deactivate ' + fullname(user) } visible={isModalVisible} onCancel={handleCancel} footer={null} width={400}>
        <p>What happens when you delete a user?.</p>
        <p>The userwill no longer be able to sign in to his or her account.</p>
        <div style={{ width: '100%', display:'flex', justifyContent:'space-around' }} >
            <Button onClick={handleCancel} style={{ borderRadius: '5px' }} block type='default'>No</Button>
              <div style={{width: '20px'}} />
            <Button onClick={handleOk} style={{ borderRadius: '5px' }} block type='primary'>Yes</Button>
        </div>
      </Modal>
    )
}


function renderWalletInfo() {

    const walletStyle = { 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-evenly',
        padding: '1rem', 
        paddingBottom: '.5rem',
        border: '1px solid #E3E3E3', 
        borderRadius: '15px',  
    }

    return (
        <>
        <Typography.Title level={5}>Wallet Balance</Typography.Title>
        <Row className='bit-grid'>
            {
                userWallets.length > 0
                &&
                userWallets.map(wallet => (
                   <div key={ wallet &&  wallet.title} style={walletStyle}>
                     <Space direction='horizontal' size='small' style={{alignItems: 'center', display: 'flex'}} >
                        <Image src={wallet && wallet.flag} preview={false} width={35} />
                        <Typography.Text>{ wallet && wallet.title}</Typography.Text>
                    </Space>
                    {/* wallet && typeof wallet.balance === 'number' ? wallet.balance : wallet['$numberDecimal'] */}
                     <Typography.Title level={3}>{
                     wallet &&  wallet.balance }
                     </Typography.Title>
                   </div>
                ))
            }
        </Row>
        </>
    )
}



// Render Identity Verification
function renderIdentityVerification() {
    const btnStyle = {
        paddingRight:'1.2rem', 
        paddingLeft:'1.2rem', 
        backgroundColor: 'rgb(128 0 32 / 10%)', 
        color: '#800020',
        display: 'flex', 
        alignItems: 'center',
        border: 'none',
    } 
    const columns = [
        {
            key: 'identityType',
            dataIndex: 'identityType',
            title: 'Identity Type',
        },
        {
            key: 'dateVerified',
            dataIndex: 'dateVerified',
            title: 'Date Verified'
        },
        {
            key: 'status',
            title: 'Status',
            render: (text, record) => (
                <Typography.Text style={{ color: text.status === 'Verified' ? '#28D781' : 'red' }} >{text.status}</Typography.Text>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
               
                 <Button onClick={() => previewImage(record)} style={{ ...btnStyle, paddingLeft: '15px !important', paddingRight: '15px !important'}} shape="round" size={10}>
                  View
                 </Button>
                 <Button  onClick={() => {}} style={btnStyle} shape="round" size={10}>
                  Download
                 </Button>
                
              </Space>
            ),
          },
    ]

    idTableData = [
        {
            key: 'identityType',
            identityType: 'Voter’s card',
            dateVerified: '12-02-2020',
            status: 'Verified'
        }
    ]
    return (
        <BitTable 
        dataSource={idTableData} 
        columns={columns} 
        // pagination={{ 
        //     total: idTableData.length,
        //     // showSizeChanger: true,
        //     // showQuickJumper: true,
        //     showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
        //     defaultPageSize: pageSize,
        //     pageSize,
        //     defaultCurrent: 1
        //   }} 
          />
    )
}

function previewImage(rec) {
    console.log(rec);
    setImagePreview(!imagePreview)
}

function renderIdPreviewModal() {
    return (
        <Image
        width={0}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        preview={{
            visible: imagePreview,
            onVisibleChange: (visible, previewVisible) => {
                setImagePreview(visible)
            }
        }}
      />
    )
}


function renderActivities() {
    const rowStyle = {
        marginLeft: '3rem',
        marginRight: '3rem',
        paddingBottom: '1rem',
        borderBottom: '0.5px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#f3f3f3'
    }

    const increaseStyle = {
        backgroundColor: 'rgb(111 185 143 / 10%)',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50px',
        width: '50px',
    }

    const amountStyle = {
        fontWeight: 'bold',
        fontSize: '16px'
    }
    const dateStyle ={
        color: '#A2A698',
        fontSize: '13px'
    }

    const secondColStyle = {
        display:'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-end',
        textAlign: 'justify'
    }

    return (
        <Row style={rowStyle}>
            <Col span={16}>
                <Space> 
                    <div style={increaseStyle}>
                      <UpOutlined style={{color: '#6FB98F'}} />
                    </div>
                    <Typography.Text>Bought 1 Sanu gold</Typography.Text>
                </Space>
            </Col>
            <Col span={4} offset={4} style={secondColStyle}>
                {/* <Space direction='vertical' size={2}> */}
                   <Typography.Text style={amountStyle} >N667,268.84</Typography.Text>
                   <Typography.Text style={dateStyle}>07-06-2020</Typography.Text>
                {/* </Space> */}
            </Col>
        </Row>
    )
}

    return (
        <AppLayout>
        <Content>
            {/* Page Header */}
            <PageTitle title='Users' />

            {/* Render Deactivate Modal */}
            {renderDeleteModal()}

            <Breadcrumb separator='>'>
                 <Breadcrumb.Item>
                    <Link to='/users' style={{color: '#800020',  }}>Users</Link>
                 </Breadcrumb.Item>
                 <Breadcrumb.Item >
                     <Typography.Text style={{ color: '#828282', textTransform: 'capitalize' }}>{user && user.firstName && user.lastName  && user.firstName + ' ' + user.lastName }</Typography.Text>   
                 </Breadcrumb.Item>
            </Breadcrumb>
            
              {/* Page Content */}
              <div className='bit-my  bit-col' style={{flexDirection: 'column', maxHeight: '713px', height: '100%'}}>
                  <Row  style={{  padding: '3rem'}}>
                          {/* Render Personal Info */}
                          {renderPersonalInfo()}
                  </Row>
              </div>
            
            {/* Wallet Section */}
              <div className='bit-my  bit-col' style={{flexDirection: 'column', maxHeight: '713px', height: '100%'}}>
                  <div  style={{  padding: '3rem'}}>
                          {/* Render Personal Info */}
                          {renderWalletInfo()}
                  </div>
              </div>
          
            {/* Id Verification Section */}
              <div className='bit-my  bit-col bit-identity-verifcation' style={{flexDirection: 'column', maxHeight: '713px', height: '100%'}}>
                  <Typography.Text style={{paddingLeft: '3rem', paddingTop: '2rem', fontSize: '20px', fontWeight: 400}}>Identity Verification</Typography.Text>
                  <div  style={{   paddingTop: '2rem', paddingBottom: '3rem'}}>
                         {renderIdentityVerification()}
                         {renderIdPreviewModal()}
                  </div>
              </div>
         
            {/* Activities Section */}
              <div className='bit-my  bit-col' style={{flexDirection: 'column', maxHeight: '713px', height: '100%'}}>
                  <Typography.Text style={{paddingLeft: '3rem', paddingTop: '2rem', fontSize: '20px', fontWeight: 400}}>Activities</Typography.Text>
                  <div  style={{  paddingTop: '2rem', paddingBottom: '3rem'}}>
                         {renderActivities()}
                  </div>
              </div>
        </Content>
    </AppLayout>
    )
}

export default UserDetails

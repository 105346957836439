import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// pages
import Login from '../Pages/login'
import Dashboard from '../Pages/Dashboard'
import Deposits from '../Pages/Deposits'
import BuyRequest from "../Pages/BuyRequest";
import SellRequest from "../Pages/SellRequest";
import Withdrawals from "../Pages/Withdrawals";
import Transaction from "../Pages/Transactions";
import Rate from "../Pages/Rate";
import Wallet from "../Pages/Walet";
// import Transaction from "../Pages/Transactions";
import Account from "../Pages/Account";
import Logout from "../Pages/Logout";
import AddWallet from "../Pages/AddWallet";
import Users from "../Pages/Users";
import UserDetails from "../Pages/UserDetails";
import Layaways from "../Pages/Layaways";

const AppRouter = () => {

 return ( 

   <BrowserRouter>
      <div>
        <Switch>
          <Route path="/" component={Login} exact/>
          <Route path="/dashboard" component={Dashboard} exact/>
          <Route path='/account' component={Account} exact />
          <Route path="/deposit" component={Deposits} exact/>
          <Route path='/buy' component={BuyRequest} exact/>
          <Route path='/sell' component={SellRequest} exact />
          <Route path='/withdrawals' component={Withdrawals} exact/>
          <Route path='/transactions' component={Transaction} exact/>
          <Route path='/users' component={Users} exact />
          <Route path='/users/:id' component={UserDetails} exact />
          <Route path='/rates' component={Rate} exact/>
          <Route path='/layaways' component={Layaways} exact/>
          <Route path='/wallets' component={Wallet} exact/>
          <Route path='/wallets/add' component={AddWallet} exact/>
          <Route path='/logout' component={Logout} exact/>
          <Route component={()=> 'not found'} /> 
        </Switch>
      </div>
    </BrowserRouter> 

  )

}


export default AppRouter;
// localhost:8080/v1/transactions?userId=nkmdsnjdskbds
// accounts?
// /rates
// admins/user_wallets